
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next"
import i18next  from 'i18next';

const traslateEn = require('./translations/en/global.json');
const traslateEs = require('./translations/es/global.json');
let global_es = traslateEs
let global_en = traslateEn

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('i18nextLng') || 'es',
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});
fetch(`${process.env.REACT_APP_BACKEND_URL}/api/translations` , {
  method: "GET",
})
  .then((res) => res.json())
  .catch((err) => err)
  .then((response) => {

    global_es = response.response && response.response.es && response.response.es || traslateEs;
    global_en = response.response && response.response.en  && response.response.en  || traslateEn;

    i18next.init({
      interpolation: { escapeValue: false },
      lng: localStorage.getItem('i18nextLng') || 'es',
      resources: {
        es: {
          global: global_es,
        },
        en: {
          global: global_en,
        },
      },
    });

  
  });
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  );
  reportWebVitals();