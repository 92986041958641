import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from "react-i18next";
import {getCasesMenu} from '../../actions/cases'
import TableCases from './table-cases';
import { casesConsult } from '../../actions/cases';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ModalCases(props) {
  const {startComparison, setSecondCases, //setComparations,
     setCopyFilteredCasesMenu , setStartComparison,setLayers,
      setIndexCaseOne, setIndexCaseTwo, setFilteredCasesMenu,
       setCopyFilteredCases, setPinState, setListState, filteredCases,
        setCases,cases, setCounterCase, setCasesToCompare,loader, setLoader, getMenuCases, UpdateMenuCases } = useContext(UserContext);

  const [open, setOpen] = React.useState(false);
  const [activeList, setActiveList] = React.useState([])
  

  const handleClickOpen = async () => {
    
    setActiveList(filteredCases)
    try {
      setLoader(false);
      setOpen(true);
      if(cases.length === 0 ){ 
      setLoader(true);
     casesConsult().then((info) =>{
      if (info.status === 200) {
        setLoader(false);
        setCases(info.response);}
     })
 
      
      }

    } catch (error) {
      console.log(error)
    }

  };
  
  const handleClose = (action) => {

    let  filterCasesMenu = filteredCases.map(row=> row._id)

    if(activeList.length >= 0 && action === "close"){
        setOpen(false)
    }else{
      setLoader(true)
      getCasesMenu({cases: filterCasesMenu}).then(response => {
        const filteredCasesCopy =response.response.cases  
        setFilteredCasesMenu(filteredCasesCopy);
        setCopyFilteredCasesMenu(filteredCasesCopy);
        setPinState(new Array(filteredCases.length).fill(false));
        const temporal = {}
        filteredCases.map(row=>temporal[row._id]=false)
        setListState(temporal);
        setCounterCase([]);
        setCasesToCompare([]);
        setOpen(false);
        setIndexCaseOne({})
        setIndexCaseTwo({})
        startComparison && setStartComparison(!startComparison);
        setLayers({})
        setSecondCases([])
      })
    }

    
  };

  const [t, i18next] = useTranslation("global")

  return (
    <>

      <div style={{ display: "inline-block" }}>

        <IconButton aria-label="fingerprint" color="primary" onClick={handleClickOpen} >
          <LibraryBooksIcon style={{ color: '#939494' }} />
        </IconButton>

        <BootstrapDialog onClose={() => {handleClose("close")}} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition} fullWidth={true} maxWidth='md'>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose("close")}}>
            {t("board.casesHistory.casesHistory")}
          </BootstrapDialogTitle>
          <DialogContent dividers sx={{ overflow: 'hidden' }}>
            {loader ? <LinearProgress /> : <TableCases />}

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => {handleClose("")}}>
              {t("board.casesHistory.popUpUpload")}
            </Button>
          </DialogActions>

        </BootstrapDialog>
      </div>

    </>
  );

}