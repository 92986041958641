import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

import ModalMitigationActions from '../../layouts/layout-user/modal-mitigation-actions';
import ModalEditeCase from '../../layouts/layout-user/modal-edit-case';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import {downloadZip} from '../../actions/download'
import SnackbarAlert from '../../layouts/layout-user/snackbar-alert';
import { useTranslation } from "react-i18next";

const columns = [
    {
        id: 1,
        label: 'Nivel',
        minWidth: 30,
        align: 'center',
    },
    {
        id: 2,
        label: 'Flexibilidad',
        minWidth: 30,
        align: 'center',
    },
    {
        id: 3,
        label: 'Contingencia',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 4,
        label: 'Elemento',
        minWidth: 100,
        align: 'center',
    },
    {
        id: 5,
        label: <TaskAltIcon />,
        minWidth: 30,
        align: 'center',
    },
    {
        id: 6,
        label: 'Límite [MW]',
        minWidth: 30,
        align: 'center',
    },
    {
        id: 7,
        label: '% Criticidad',
        minWidth: 30,
        align: 'center',
    },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Item = ({ row, father, setDisplayButton, setListMonitored, listMonitored }) => {
    const [checked, setChecked] = useState([]);
    row.constraints = row.constraints && typeof row.constraints === typeof [] ? row.constraints.sort(function(a, b){
       
        if(a.ranking < b.ranking) { return 1; }
        if(a.ranking > b.ranking) { return -1; }
        return 0;
    }) : row.constraints
    const handleClickCB = (event, index, contingency, monitored, scenarios) => {
        let newObje = {};
        newObje.contingency = contingency;
        newObje.monitored = monitored;
        newObje.scenarios = scenarios;

        const newChecked = [...checked];
        newChecked[index] = !newChecked[index];
        setChecked(newChecked);

        const elementoExistente = listMonitored.find(
            elemento => elemento.contingency === newObje.contingency && elemento.monitored === newObje.monitored
        );

        if (elementoExistente) {
            const nuevosElementos = listMonitored.filter(
                elemento => elemento.contingency !== newObje.contingency || elemento.monitored !== newObje.monitored
            );
            setListMonitored(nuevosElementos);
        } else {
            setListMonitored([...listMonitored, newObje])
        }

    }

    useEffect(() => {
        if (row.constraints && row.constraints.length > 0) {
            setChecked(row.constraints.map(c => false))
        }
    }, [row])

    useEffect(() => {
        const trueValueExists = checked.some(valor => valor === true);
        if (trueValueExists) {
            setDisplayButton(true)
        } else {
            setDisplayButton(false)
        }
    }, [checked])

    const returnColor = (flexibility) => {
        let color = '';
        if (flexibility > 79) {
            color = 'rgb(0, 153, 255)';
        } else if (flexibility >= 40 && flexibility <= 79) {
            color = 'rgb(245, 169, 5)';
        } else {
            color = 'rgb(210, 119, 73)';
        }
        return color;
    }

    return (
        <StyledTableRow
            role="checkbox"
            tabIndex={-1}
        >
            {/* nivel */}
            <TableCell
                align='center'
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0
                }}
            >
                {row.level}
            </TableCell>
            {/* //Flexibilidad */}
            <TableCell
                align='center'
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{
                        background: returnColor(row.flexibility.toFixed(0)),
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                        marginRight: 10
                    }}
                    ></div>
                    {
                        row.flexibility.toLocaleString(
                            undefined,
                            {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1
                            })
                    }

                </div>
            </TableCell>
            {/* //Contingencia */}
            <TableCell
                align='center'
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0,
                    // height: '74px'
                }}
            >
                {row.constraints.length === 0 || !row.constraints ? (
                    <div
                        style={{
                            height: '45px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {row.constraints.contingency}
                    </div>
                ) : (

                    <>
                        {row.constraints.map((constraint, index) => (
                            constraint.contingency === '' ? (
                                <div
                                    key={index}
                                    style={{
                                        height: '45px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                                    }}
                                >
                                    {constraint.contingency}
                                </div>
                            ) : (
                                <div
                                    key={index}
                                    style={{
                                        height: '45px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                                    }}
                                >
                                    <ModalMitigationActions
                                        key={index}
                                        info={constraint.contingency}
                                        caseStudy={father}
                                        index={index}
                                        row={row.constraints[index]}
                                    />
                                    {/* {constraint.contingency} */}
                                </div>
                            )
                        ))}
                    </>
                )

                }

            </TableCell>
            {/* //Elemento */}
            <TableCell
                align='center'
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0,
                    height: '74px'
                }}
            >
                {row.constraints.map((constraint, index) => (
                    <div
                        key={index}
                        style={{
                            height: '45px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                        }}
                    >
                        <ModalMitigationActions
                            info={constraint.monitored}
                            caseStudy={father}
                            index={index}
                            row={row.constraints[index]}
                        />
                        {/* {constraint.monitored} */}
                    </div>
                ))}
            </TableCell>
            {/* //checked */}
            <TableCell
                align='left'
                // ref={(el) => { elementsRef.current[index] = el; }}
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0,
                    height: '74px'
                }}
                id='celda-table'
            >
                {row.constraints.map((constraint, index) => (
                    <div
                        key={index}
                        style={{
                            height: '45px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                        }}
                    >
                        <Checkbox
                            key={index}
                            onClick={(event) => handleClickCB(event, index, constraint.contingency, constraint.monitored, constraint.scenarios)}
                            checked={checked[index] ? checked[index] : false}
                            onChange={() => { }}
                            inputProps={{ 'aria-label': 'controlled' }}
                            scenarios={constraint.scenarios}
                        />
                    </div>
                ))}

            </TableCell>
            {/* Límite [MW] */}
            <TableCell
                align='center'
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0,
                    height: '74px'
                }}
            >
                {row.constraints.map((constraint, index) => (
                    <div
                        key={index}
                        style={{
                            height: '45px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                        }}
                    >
                        {constraint.ranking.toString().endsWith('-') ? (
                            <>
                                {constraint.limit ? constraint.limit.toLocaleString() : "0 "}{' '}
                                <span style={{ color: 'red', marginLeft: 5, fontWeight: 700 }}>Out</span>
                            </>
                        ) : constraint.limit.toString().includes('+') ? (
                            <>
                                {constraint.limit ? constraint.limit.toLocaleString() : "0 "}{' '}
                                <span style={{ color: 'green', marginLeft: 5, fontWeight: 700 }}>In</span>
                            </>
                        ) : (
                            constraint.limit ? constraint.limit.toLocaleString() : "0"
                        )}
                    </div>
                ))}
            </TableCell>
            {/* % Criticidad */}
            <TableCell
                align='center'
                style={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                    padding: 0,
                    height: '74px'
                }}
            >
                {row.constraints.map((constraint, index) => (
                    <div
                        key={index}
                        style={{
                            height: '45px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                        }}
                    >
                        {constraint.ranking.toString().endsWith('-') ? (
                            <>
                                {constraint.ranking ? constraint.ranking.toLocaleString() : "0 "}{' '}
                                <span style={{ color: 'red', marginLeft: 5, fontWeight: 700 }}>Out</span>
                            </>
                        ) : constraint.ranking.toString().includes('+') ? (
                            <>
                                {constraint.ranking ? constraint.ranking.toLocaleString() : "0 "}{' '}
                                <span style={{ color: 'green', marginLeft: 5, fontWeight: 700 }}>In</span>
                            </>
                        ) : (
                            constraint.ranking ? constraint.ranking.toLocaleString() : "0"
                        )}

                    </div>
                ))}
            </TableCell>
        </StyledTableRow>
    )
}

export default function LayersDatails(props) {

    const { layers } = useContext(UserContext);
    const [t, i18next] = useTranslation("global")

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selected, setSelected] = React.useState(null);
    const [checkboxStates, setCheckboxStates] = React.useState(Array(5).fill(false));
    const [displayButton, setDisplayButton] = React.useState(false);
    const [listMonitored, setListMonitored] = useState([]);
    const [serverResponse, setServerResponse] = React.useState(null);
    const [rows, setRows] = React.useState([]);

    const father = layers[0];

    React.useEffect(() => {
        setRows(layers[0].caseResult[0].layers)
    }, [rows, layers])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClick = (item) => {
        setSelected(item);
    }

    const itemStyle = (item) => {
        return {
            backgroundColor: selected === item ? '#f1f1f1' : 'white',
        }
    }

    const handleChangeCheckbox = (index) => {
        setCheckboxStates((prev) => {
            const newStates = [...prev];
            newStates[index] = !prev[index];
            return newStates;
        });
    };

    function retunrColor(flexibility) {
        let color = '';
        if (flexibility > 79) {
            color = 'rgb(0, 153, 255)';
        } else if (flexibility >= 40 && flexibility <= 79) {
            color = 'rgb(245, 169, 5)';
        } else {
            color = 'rgb(210, 119, 73)';
        }
        return color;
    }

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    }

    async function downloadZipDocument() {
        try {
            
            downloadZip({files:listMonitored}).then(async (response) =>{
                // convertir los datos binarios en un objeto Blob
                
                const fileName = `${father.caseResult[0].caseName}.zip`;
                // crear una URL de objeto para descargar el archivo
                const blob = await response.blob()
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            })
            

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
                <TableContainer
                    sx={{ height: "calc(100vh - 180px)" }}
                >
                    <Table aria-label="sticky table" stickyHeader >
                        <TableHead >
                            <TableRow>
                                <TableCell align="center" colSpan={2} style={{padding:0}}>
                                    <div style={
                                        {
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }
                                    }
                                    >
                                        <div>
                                            <Typography variant='h6'>
                                                {father.caseResult[0].caseName}
                                            </Typography>
                                        </div>

                                        <div>
                                            <ModalEditeCase
                                                idCase={father._id}
                                                setServerResponse={setServerResponse}
                                                infoCases={father}
                                            // arrayCases={props.arrayCases}
                                            />
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" colSpan={2} style={{padding:0}}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div>
                                            <Typography variant='h6'>
                                                {formatDate(father.creationDate)}
                                            </Typography>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell align="center" colSpan={3} style={{padding:0}}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {listMonitored.length > 0 &&
                                            <Button
                                                variant="outlined"
                                                color='inherit'
                                                startIcon={<DownloadIcon />}
                                                size="small"
                                                onClick={downloadZipDocument}
                                            >
                                                Descargar
                                            </Button>
                                        }

                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" colSpan={2}  style={{padding:0}}>

                                </TableCell>
                                <TableCell
                                    align="center"
                                    colSpan={2}
                                    style={{ background: "#e0e1de", color: "#9b9c9a", padding:0}}
                                >
                                    <b>{t("board.casesTable.restriction")}</b>
                                </TableCell>
                                <TableCell align="center" colSpan={3}  style={{padding:0}}>

                                </TableCell>
                            </TableRow>
                            <TableRow >
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ top: 57, minWidth: column.minWidth, padding:0
                                     }}
                                    >
                                        <b key={index}>{column.label}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        
                    </Table>
                        <Table aria-label="sticky table" stickyHeader >
                        <TableHead ></TableHead>
                        <TableBody >
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <>
                                            {!row.constraints || row.constraints.length === 0

                                                ? (
                                                    <StyledTableRow
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.code}
                                                        style={{ height: 60 }}
                                                    >

                                                        <TableCell
                                                            align='center'
                                                            style={{
                                                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                                padding: 0
                                                            }}
                                                        >
                                                            {row.level}
                                                        </TableCell>

                                                        <TableCell
                                                            align='center'
                                                            style={{
                                                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                                padding: 0
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    background: retunrColor(row.flexibility.toFixed(0)),
                                                                    borderRadius: "50%",
                                                                    width: 20,
                                                                    height: 20,
                                                                    marginRight: 10
                                                                }}
                                                                ></div>
                                                                {
                                                                    row.flexibility.toLocaleString(
                                                                        undefined,
                                                                        {
                                                                            minimumFractionDigits: 1,
                                                                            maximumFractionDigits: 1
                                                                        })
                                                                }
                                                            </div>
                                                        </TableCell>

                                                        <TableCell
                                                            align='center'
                                                            style={{
                                                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                                padding: 0
                                                            }}
                                                            colSpan={5}
                                                        >
                                                            {row.flexibility === 0
                                                                ? 'No hay región segura'
                                                                : 'No existen restricciones en este nivel'}
                                                        </TableCell>


                                                    </StyledTableRow>
                                                )

                                                : (
                                                    <Item
                                                        key={row.code}
                                                        father={father}
                                                        row={row}
                                                        setDisplayButton={setDisplayButton}
                                                        setListMonitored={setListMonitored}
                                                        listMonitored={listMonitored}
                                                    />
                                                )
                                            }
                                        </>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("board.casesHistory.rowsPerPage")}
                />
            </Paper>

            <SnackbarAlert
                vertical="top"
                horizontal="center"
                serverResponse={serverResponse}
                message={'Se ha actualizado el caso correctamente'}
                severity={'success'}
            />
        </>

    );

}





