import * as React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ModalRegistration(props) {

    const [loading, setLoading] = React.useState(false);

    const downloadDemo = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.BACKEND_URL}/api/v1/download-demo`, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sax-demo.exe');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setLoading(false);
            props.onClose();
        } catch (error) {
            console.error('Error al descargar el archivo', error);
        }
    };

    return (
        <div>

            <BootstrapDialog
                onClose={props.onClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    Solicitud exitosa
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Tu solicitud para el demo ha sido procesada con éxito. Espera a que el administrador se comunique con tigo.
                    </Typography>

                    {loading &&
                        <LinearProgress color="secondary" />
                    }
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            onClick={()=>{ 
                                if (props.data.platform == 'Ambos'){
                                window.open( `${process.env.REACT_APP_BACKEND_URL}/api/download-demo/PowerFactory`);
                                window.open( `${process.env.REACT_APP_BACKEND_URL}/api/download-demo/PSSE`);}
                                else {
                                    window.open( `${process.env.REACT_APP_BACKEND_URL}/api/download-demo/${props.data.platform}`)
                                }
                                 props.onClose(); setLoading(false);}}
                            variant="contained"
                            color="secondary"
                            style={{
                                background: '#7220db',
                                borderRadius: 2,
                                textTransform: 'none',
                                fontSize: 16,
                                marginTop: 7,
                                marginBottom:7,
                                fontWeight: 700,
                            }}
                        >
                            Descargar demo  
                        </Button>
                    </div>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
