import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { INFO_PLANS } from '../config/api';
import { plansConsult } from '../actions/plans.js';
import { saveRequestPlan }  from '../actions/requestPlan';

const PlansAndServicesContext = createContext();

const ContextPlansAndServicesProvider = ({ children }) => {

    const [infoPlans, setInfoPlans] = useState([]);
    const [features, setFeatures] = useState([]);
    const [openLoader, setOpenLoader] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [messageAlert, setMessageAlert] = useState(false);
    const [planStateSelect, setPlanStateSelect] = useState(true)

    const [plan, setPlan] = useState({
        creation_date: '',
        payment_date: '',
        start_date: '',
        end_date: '',
        time_plan: 1,
        status: '',
        plan_value: 1090,
        total_user: 1,
        is_demo: '',
        features: [
            { feature_id: 5, name: 'Usuario',quantity: 1, unite_price: 1090,  }
        ],
    })

    const requestInfoPlan = async () => {
        try {
            setOpenLoader(true);
            const response = await plansConsult();
            
            if (response.status === 200) {
                setOpenLoader(false);
                setInfoPlans(response.response);
                setFeatures(response.response.listOne[0].features[0].features);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const submitPlanRequest = async (data) => {
        try {
            setOpenLoader(true);
            const response = await saveRequestPlan(data);
            if (response.status === 200) {
                setOpenLoader(false);
                return response;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const contextValue = {
        infoPlans, setInfoPlans, requestInfoPlan,
        openLoader, setOpenLoader,
        features, setFeatures,
        plan, setPlan,
        planStateSelect, setPlanStateSelect,
        openAlert, setOpenAlert, messageAlert, setMessageAlert,
        submitPlanRequest
    };

    return (
        <PlansAndServicesContext.Provider value={contextValue}>
            {children}
        </PlansAndServicesContext.Provider>
    )

}

export { PlansAndServicesContext, ContextPlansAndServicesProvider };