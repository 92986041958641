import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';
import { Troubleshoot } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function ModalDialogComponent(props) {
    const {view ,tittle, message, buttons}=props
    const [loading, setLoading] = React.useState(false);
    const [close,setClose]  =React.useState(false);
    
    return (
<div>
{(<BootstrapDialog
    // onClose={close}
    aria-labelledby="customized-dialog-title"
    open={view}
    TransitionComponent={Transition}
>
    <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
        {tittle}
    </BootstrapDialogTitle>
    <DialogContent dividers>
        <Typography gutterBottom>
           {message}
        </Typography>
        {props.render }
        {loading &&
            <LinearProgress color="secondary" />
        }
    </DialogContent>
    <DialogActions>
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
           {buttons && buttons.map((row)=> (
           <Button
                onClick={row.onclick}
                variant="contained"
                color="secondary"
                style={{
                    background: row.color,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontSize: 16,
                    marginTop: 7,
                    marginLeft: 10,
                    marginBottom:7,
                    fontWeight: 700,
                }}
            >
               { row.value}
            </Button>))}
        </div>
    </DialogActions>
</BootstrapDialog>)}

</div>)
}