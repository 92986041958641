import './App.css';
import { RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import router from "./routes";
import React, { useState, useForm, useEffect } from "react";
import { validToken } from "./actions/users"
import { NetworkProvider } from "react-query";
import jwt_decode from "jwt-decode";
import { PersistGate } from "react-persist";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { translationsRequest } from '../src/actions/translations';


const setToken = (payload) => ({ type: 'SET_TOKEN', payload });

const getToken = (token) => {
  return (dispatch) => {
    dispatch(setToken(token));
  };
};
const localStoragePersistor = {
  // Persistir el estado de la aplicación en localStorage
  save: (state) => {
    localStorage.setItem("saxToken", JSON.stringify(state));
  },
  // Recuperar el estado de la aplicación de localStorage
  load: () => {
    const state = localStorage.getItem("saxToken");
    return state ? JSON.parse(state) : {};
  },
};

function App() {
  //localStorage.removeItem("saxToken");
  !localStorage.getItem("i18nextLng")  && localStorage.setItem("i18nextLng", 'es')
  const [token, setToken] = useState(localStorage.getItem("saxToken")  );
   

  useEffect(() => {

    fetch("https://api.ipify.org", {
      method: "GET",
    }).then((res) => res.text())
      .catch((err) => err).then((response) => {
        response.includes('.') && localStorage.setItem("ip", response)
      });



//     if (localStorage.getItem("saxToken")) { 
//       validToken({ token }).then((response) => {
       
//         if (response.status === 1 && response.valid === null) {
          
//           localStorage.removeItem("saxToken");
//           setModalMessages(true);
// }
//        return
//       });
//     }else {
      
//     }

  },[]);

  return (
    <>
     
   
    <Provider store={configureStore()}>
      <SnackbarProvider maxSnack={3}>
        <div className="App" >

          <RouterProvider router={router(token)} />
        </div>
      </SnackbarProvider>
    </Provider>
    </>
  );
}

export default App;
