import React, { createContext, useState, useEffect } from 'react';
// import Decimal from 'decimal.js';
import { useParams } from "react-router-dom";
import { casesConsult, casesCreated } from '../actions/cases.js'
import { usersConsult, usersConsultAdmin } from '../actions/users.js'

let comparations ={}

const connectDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('menuDB', 6); 

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('menuCases')) {
                db.createObjectStore('menuCases', { keyPath: 'id' });
            }
        };

        request.onsuccess = function(event) {
            const db = event.target.result;
            resolve(db);
        };

        request.onerror = function() {
            reject('Error connecting to database');
        };
    });
};

const getMenuCases = async () => {
    const db = await connectDB();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['menuCases'], 'readonly');
        const store = transaction.objectStore('menuCases');
        const getRequest = store.get(1);

        getRequest.onsuccess = function() {
            const data = getRequest.result;
            if (data) {
                resolve(data.menuCases);
            } else {
                reject('No data found');
            }
        };

        getRequest.onerror = function() {
            reject('Error retrieving data');
        };
    });
};

const UpdateMenuCases = async (menuCases) => {
    const db = await connectDB();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['menuCases'], 'readwrite');
        const store = transaction.objectStore('menuCases');

        const caseData = { id: 1, menuCases: menuCases };
        const putRequest = store.put(caseData);

        putRequest.onsuccess = function() {
            resolve('Data updated successfully');
        };

        putRequest.onerror = function() {
            reject('Error updating data');
        };
    });
};


const UserContext = createContext();
let estado = false
let caseOne=[]
const UserContextProvider = ({ children, ...props }) => {
    const params = useParams();

    const [caseSelect, setCaseSelect] = useState(null);
    const [filterCase, setFlterCase] = useState({
        caseName: '',
        caseDate: '',
        caseDescription: ''
    });
    const [cases, setCases] = useState([]);
    const [listUsers, setListUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);
    const [usersAdmin, setUsersAdmin] = useState([]);
    const [filteredCases, setFilteredCases] = useState([]);
    const [filteredCasesMenu, setFilteredCasesMenu] = useState([]);
    const [copyFilteredCasesMenu, setCopyFilteredCasesMenu] = useState([]);
    const [layers, setLayers] = useState({});
    const [newArrayNames, setNewArrayNemes] = React.useState([]);
    const [pinState, setPinState] = React.useState(Array(filteredCases.length).fill(false));
    const [listState, setListState] = React.useState(Array(filteredCases.length).fill(false));
    const [indexCaseOne, setIndexCaseOne] = React.useState({});
    const [indexCaseTwo, setIndexCaseTwo] = React.useState({});
    const [openModalUsers, setOpenModalUsers] = React.useState(false);
    const [openNoti, setOpenNoti] = React.useState(false);
    const [typeNotification, setTypeOfNotification] = React.useState('');
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [loaderCase, setLoaderCase] = React.useState(false);
    const [openModalCaseInfo, setOpenModalCaseInfo] = React.useState(false);
    const [nameToEdit, setNameToEdit] = React.useState('');
    const [descriptionToEdit, setDescriptionToEdit] = React.useState('');
    const [idCaseToEdit, setIdCaseToEdit] = React.useState('');
    // const [comparations, setComparations] = React.useState([]);
    const [casesToCompare, setCasesToCompare] = useState([]);
    const [counterCase, setCounterCase] = useState([]);
    const [changeRows, setChangeRows] = useState([]);
    const [secondCases, setSecondCases]=useState([]);
    const [startComparison, setStartComparison] = React.useState(false);
    const [pin, setPin] = React.useState(false)

    const [seeIcon, setSeeIcon] = React.useState(Array(filteredCases.length).fill(false));

    const [ reminderMessage, setReminderMessage ] = useState( false );

    const handleClickOpenModalCaseInfo = (name, description, idCase) => {
        setOpenModalCaseInfo(true);
        setNameToEdit(name);
        setDescriptionToEdit(description);
        setIdCaseToEdit(idCase);
    };

    const handleCloseModalCaseInfo = () => {
        setOpenModalCaseInfo(false);
    };


    const handleClickNoti = () => {
        setOpenNoti(true);
    };

    const handleCloseNoti = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoti(false);
    };

    const handleClickOpenModalUsers = (user) => {
        setOpenModalUsers(true);
        setUsers(user)
    };

    const handleCloseModalUsers = () => {
        setOpenModalUsers(false);
    };
    
    // --------------------info cases----------------------
    useEffect(() => {
        UpdateMenuCases(copyFilteredCasesMenu)
    },[copyFilteredCasesMenu])

 
    useEffect(() => {
        if (props.case) {
            setLoaderCase(true)
            casesCreated(props.case).then(response =>{
              
            
                if (response.response && response.response.case){
                    setLayers(response.response.case)
                    setCaseSelect(props.case)
                    setFilteredCases([response.response.case])
                    setFilteredCasesMenu([response.response.case])}
                setLoaderCase(false)  
            })
                }
    }, [props.case]);

    useEffect(() => {
    
        props.case && setCaseSelect(props.case)

        const fetchData = async () => {
            try {
                const [casesResponse, companiesResponse] = await Promise.all([
                    casesConsult(),
                    usersConsult(),
                ]);

                setCases(casesResponse.response);
                setCompanies(companiesResponse.response);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    
    function filtrarArray(array, filtro, propiedad) {
        return array.filter(elemento => filtro.includes(elemento[propiedad]));
    }

    useEffect(() => {

    }, [pinState]);


    const updatePinState = (pinState, indexPin) => {
        const nuevosBooleans = [...pinState];
        nuevosBooleans[indexPin] = !nuevosBooleans[indexPin];
        setPinState(nuevosBooleans);
    }

    const updateListState = (listState, indexPin) => {

        const nuevosBooleans = [...listState];

        nuevosBooleans.map((list, index) => {
            if (list === true) {
                nuevosBooleans[index] = false;
            }
        })
        nuevosBooleans[indexPin] = !nuevosBooleans[indexPin];
        setListState(nuevosBooleans);
    }

    const convertToZeroValues = (Element) => {
        Element.caseResult[0].layers.forEach(layer => {
            //layer.flexibility = 0;
            layer.constraints.forEach(constraint => {
                constraint.limit = 0;
                constraint.ranking = 0;
            });
        });
    }
    const convertToZero = (case_) =>{
        
        let leyers  = case_.caseResult[0].layers.map(layer => ({...layer , 
            'flexibility' : 0, 'constraints': layer.constraints.map(row=> ({...row,'limit': 0, 'ranking': 0 }))}
            ))
        let temporal = {...case_, 'caseResult': [{...case_.caseResult[0] , 'layers' : leyers }]}
        return temporal
    }


    const calculateFlexibility = (infoCaseOne, infoCaseTwo) => {
        
        let caseOne = infoCaseOne._id ==  infoCaseTwo._id
        let dataCase  = {}
    
        if(caseOne){
           
             dataCase  = convertToZero(infoCaseOne)
             dataCase.caseResult[0].caseName =  `${infoCaseOne.caseResult[0].caseName}`
             
        }else {
        const caseOne = infoCaseOne.caseResult[0].layers;
        const caseTwo = infoCaseTwo.caseResult[0].layers;
            
        const numOne = caseOne;
        const numTwo = caseTwo;
        

        const lengthOfLargerArray = Math.max(numOne.length, numTwo.length);
        /**
         * Some cases have level 0, so it is necessary to add a validation on these cases.
         */
        const fromZeroLevel = Boolean(caseOne.find(obj => obj.level === 0) && caseTwo.find(obj => obj.level === 0))
        

        for (let i = 0; i < lengthOfLargerArray; i++) {

            const flexibilityOne = fromZeroLevel ? caseOne.find(obj => obj.level === i ) : caseOne.find(obj => obj.level === i + 1);
            const flexibilityTwo = fromZeroLevel ?  caseTwo.find(obj => obj.level === i ) : caseTwo.find(obj => obj.level === i + 1);

            
            if (flexibilityTwo && flexibilityOne) {
                    
                if (flexibilityTwo.constraints.length === 0) {
                    for (let x = 0; x < flexibilityOne.constraints.length; x++) {
                        flexibilityOne.constraints[x].limit = flexibilityOne.constraints[x].limit + '-';
                        flexibilityOne.constraints[x].ranking = flexibilityOne.constraints[x].ranking + '-';
                    }
                    caseTwo[i] = flexibilityOne;
                }

                if (flexibilityTwo.constraints.length > 0) {
                    
                    caseTwo[i].flexibility = caseTwo[i].flexibility - caseOne[i].flexibility

                    const equals = () => {
                        const one = flexibilityOne.constraints.filter(objTwo => {
                            return flexibilityTwo.constraints.some(objOne => objOne.contingency === objTwo.contingency && objOne.monitored === objTwo.monitored);
                        });

                        const two = flexibilityTwo.constraints.filter(objTwo => {
                            return flexibilityOne.constraints.some(objOne => objOne.contingency === objTwo.contingency && objOne.monitored === objTwo.monitored);
                        });
                       
                        
                        if (one && two) {
                            
                            two.map((infoOne, index) => {
                             
                                const resta = one.find(obj => obj.contingency === infoOne.contingency && obj.monitored === infoOne.monitored);
                
                                infoOne.limit = infoOne.limit && resta.limit ? Math.round(infoOne.limit) - Math.round(resta.limit) : 0
                                
                                infoOne.ranking = infoOne.ranking - resta.ranking;
                            })
                        }
                    }

                    const onlyInCaseOne = () => {
                        
                        const filteredArray = flexibilityOne.constraints.filter(obj2 => {
                            return !flexibilityTwo.constraints.some(obj1 => obj1.contingency === obj2.contingency && obj1.monitored === obj2.monitored);
                        });

                        filteredArray.forEach(contingency => {

                            contingency.limit = contingency.limit ? contingency.limit + '-' : 0;
                            contingency.ranking = contingency.ranking !== 0 ? contingency.ranking + '-' : 0;
                            flexibilityTwo.constraints.unshift(contingency);
                        });

                    }

                    const onlyInCaseTwo = () => {
                        
                        const filteredArray = flexibilityTwo.constraints.filter(obj2 => {
                            return !flexibilityOne.constraints.some(obj1 => obj1.contingency === obj2.contingency && obj1.monitored === obj2.monitored);
                        });

                        filteredArray.forEach(contingency => {
                            
                            contingency.limit = contingency.limit ? contingency.limit + '+' : 0;
                            contingency.ranking = contingency.ranking !== 0 ? contingency.ranking + '+' : 0;
                        });
                    }

                    equals();
                    onlyInCaseOne();
                    onlyInCaseTwo();

                }

            }

            else if (flexibilityTwo && !flexibilityOne) {

                for (let x = 0; x < flexibilityTwo.constraints.length; x++) {
                    flexibilityTwo.constraints[x].limit = flexibilityTwo.constraints[x].limit + ' +';
                    flexibilityTwo.constraints[x].ranking = flexibilityTwo.constraints[x].ranking + ' +';
                }

            }

            else if (!flexibilityTwo && flexibilityOne) {

                for (let x = 0; x < flexibilityOne.constraints.length; x++) {
                    flexibilityOne.constraints[x].limit = flexibilityOne.constraints[x].limit + '-';
                    flexibilityOne.constraints[x].ranking = flexibilityOne.constraints[x].ranking + '-';
                }

                caseTwo.push(flexibilityOne);
            }

        }
    }
        let name_case = `${infoCaseOne._id}_${infoCaseTwo._id}`
        infoCaseTwo.caseResult[0].caseName = caseOne ? `${infoCaseOne.caseResult[0].caseName}` : `${infoCaseOne.caseResult[0].caseName}_${infoCaseTwo.caseResult[0].caseName}`
        infoCaseTwo.caseResult[0].comparation = true
        let comparationsTemporal = {...comparations,[name_case]:caseOne ? {...dataCase, color : true} : {...infoCaseTwo, color : true} }
        comparations = comparationsTemporal
    
        setLayers(caseOne ? {...dataCase, color : true} : {...infoCaseTwo, color : true});

    }
    
    const createCaseComparison = async( id) => {
       
        setStartComparison(!startComparison)
        setPin(false)
        
        estado = !estado
        const caseInfo = filteredCasesMenu.find(caseF => caseF._id === id);
        if(!startComparison){
            //addLayers(id, true, true )
            setIndexCaseOne(caseInfo)
           
        }else {
            let menuCases = await getMenuCases()
            setLayers(menuCases.find(ele => ele._id === id))
            setSecondCases([])
            setFilteredCasesMenu([...menuCases]);
            setIndexCaseOne({})
            setIndexCaseTwo({})
        }
    
    }

    const addLayers = async(id, state) => {
       
        const caseInfo = filteredCasesMenu.find(caseF => caseF._id === id);
        if(!listState[id]){
            
            if (Object.keys(indexCaseOne).length === 0){
                const temporal = {...filteredCases, [caseInfo._id]:true}
                setListState(temporal);
                setLayers(caseInfo)
            }
            else { 
                setPin(true)
                let temporalMenuCases = await getMenuCases()
                let caseCompare = `${indexCaseOne._id}_${caseInfo._id}`
                
                if(caseInfo._id  !== indexCaseTwo._id  
                    &&  !Object.keys(comparations).includes(caseCompare)
                    ){ 
                        let caseOne = temporalMenuCases.find(ele =>indexCaseOne._id === ele._id)
                        let caseTwo = temporalMenuCases.find(ele =>caseInfo._id === ele._id)
                        
                    calculateFlexibility(caseOne, caseTwo) 
                }
                
                
                indexCaseTwo && caseInfo._id  !== indexCaseTwo._id &&  setFilteredCasesMenu([
                    ...filteredCasesMenu.map(row =>row._id === caseInfo._id && comparations[caseCompare]
                     ? {...comparations[caseCompare], 
                        'caseResult' : [{...comparations[caseCompare].caseResult[0],
                            'caseName':row.caseResult[0].caseName }]} : 
                            temporalMenuCases.find(ele => ele._id === row._id)
                   )])
                Object.keys(comparations).includes(caseCompare) && setLayers(  {...comparations[caseCompare]}  )
                setIndexCaseTwo(caseInfo)  
            }
        
        }else { 
            
            
            let caseCompare = `${caseInfo._id}_${caseInfo._id}`
           
            state  && Object.keys(comparations).includes(caseCompare) &&  estado ? setLayers(comparations [caseCompare]): 
            estado  && !Object.keys(comparations).includes(caseCompare) && calculateFlexibility(caseInfo, caseInfo)  
            let temporalMenuCases = await getMenuCases()
            !state && indexCaseOne &&  indexCaseOne._id ===caseInfo._id && setFilteredCasesMenu([
                ...filteredCasesMenu.map(row => temporalMenuCases.find(ele => ele._id === row._id)
               )])
            state && setIndexCaseTwo({})
            !state &&  setLayers(caseInfo)
            
        }
        
    }

    const contextValue = {
        cases,
        setCases,
        filteredCases,
        setFilteredCases,
        setFilteredCasesMenu,
        copyFilteredCasesMenu, setCopyFilteredCasesMenu,
        setCounterCase, setCasesToCompare,
        filteredCasesMenu,
        layers,
        setLayers,
        addLayers,
        filterCase,
        setFlterCase,
        filtrarArray,
        createCaseComparison,
        newArrayNames,
        setNewArrayNemes,
        pinState, setPinState,
        listState, setListState,
        indexCaseOne, indexCaseTwo,
        companies,
        users,
        caseSelect,
        setCaseSelect,
        loaderCase, setLoaderCase,
        usersAdmin,
        setUsersAdmin,
        openModalUsers,
        handleClickOpenModalUsers,
        handleCloseModalUsers,
        openNoti,
        handleClickNoti,
        handleCloseNoti,
        handleClickOpenModalCaseInfo,
        handleCloseModalCaseInfo,
        openModalCaseInfo,
        nameToEdit,
        setIndexCaseTwo,
        setNameToEdit,
        descriptionToEdit,
        setDescriptionToEdit,
        idCaseToEdit,
        typeNotification,
        setIndexCaseOne,
        setTypeOfNotification,
        setSecondCases,
        pin,
    //setComparations,
        notificationMessage,
        setNotificationMessage,changeRows, setChangeRows,
        seeIcon,
        setSeeIcon,setStartComparison,
        listUsers, 
        setListUsers,
        loader, setLoader,
        startComparison,
        reminderMessage, 
        setReminderMessage        
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserContextProvider };