import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, List, ListItem, ListItemIcon, Link } from '@mui/material';
import * as IconsMaterial from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tutorialsConsult } from '../../actions/tutorials';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';


function TutorialsList() {
  
  const [infoTutorials, setInfoTutorials] = useState([]);
  const [loading, setLoading] = useState(true);

  const createIcon = (icon) => {
  if (icon.name && icon.style){
    const Component = React.createElement(IconsMaterial[icon.name], {
      style: icon.style,
    });
    return Component
  }else{
    return 
      <div>
      </div>
  }
}

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await tutorialsConsult();

      if (response.status === 200) {
        setLoading(false)
        setInfoTutorials(response.response);
      }
    } catch (error) {
      setLoading(true)
      console.error('Error fetching tutorials:', error);
    }
  };

  fetchData();
}, []);

return (
  <div style={{ width: '1000px', margin: 'auto', marginBottom: "150px"}}>
    {loading  &&
      <LinearProgress color="secondary" />
    }
    {infoTutorials.map((category) => (
      <Accordion key={category.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#FF6A13' }} />} aria-controls={`panel${category.id}-content`} id={`panel${category.id}-header`}>
          <Typography sx={{ fontWeight: 'bold' }}>{category.category_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List style={{ background: '#f0f0f0' }}>
            {category.tutorial_info.map((tutorial) => (
              <ListItem key={tutorial.id}>
                <ListItemIcon>
                    {createIcon(tutorial.icon)}
                </ListItemIcon>
                  <Link href={tutorial.url} target="_blank" underline="hover" color="inherit">
                    <Typography sx={{ color: 'purple', fontWeight: 'bold', textDecoration: 'underline' }}>{tutorial.name}</Typography>
                  </Link>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    ))}
  </div>
);



}
export default TutorialsList;