import React, { useContext, useEffect, useState } from 'react';
import { PlansAndServicesContext } from '../../contexts/contex-plans-and-services';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import {getInfoPlan} from '../../actions/users'
import Image1 from '../../assets/icono_1.png';
import Image2 from '../../assets/icono_2.png';
import Image3 from '../../assets/icono_3.png';
import Image4 from '../../assets/icono_5.png';
import { red } from '@mui/material/colors';
import { pureFinalPropsSelectorFactory } from 'react-redux/es/connect/selectorFactory';
import  ModalDialogComponent from '../../components/dialog/dialog-component'
import { useTranslation } from "react-i18next";

const CardHover = styled(Card)({
    boxShadow: '0 2px 5px rgba(0,0,0,.3)',
    height: '100%',
    '&:hover': {
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.3s ease-in-out',
    },
})

const styleTitle = {
    fontSize: '18px',
    fontWeight: 700,
}

const styleText = {
    color: '#868686',
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '10px',
    fontWeight: 400,
    textAlign: 'start'
}

const styleRadio = {
    border: '1px solid grey',
    padding: '3px 10px 3px 10px',
    borderRadius: 3,
    margin: 0,
    width: '75%',
    textSize:'11px'

}

const styleCheck = {
    border: '1px solid grey',
    padding: '0 10px 0 10px',
    borderRadius: 3,
}

function SelectPlan() {
    const { infoPlans, requestInfoPlan,
        features, setFeatures,
        //plan, setPlan
        planStateSelect, setPlanStateSelect
    } = useContext(PlansAndServicesContext);

    const [selectedChannel, setSelectedChannel] = useState("xm");
    const [totalUsers, setTotalUsers] = useState(1);
    const [timePlans, setTimePlans] = useState(1);
    const [planOwner,setPlanOwner] = useState([]);
    const [plan, setPlan] = useState({});
    const [originalPlan, setOriginalPlan] = useState({});
    const [newPlan, setNewPlan] = useState({});
    const [checkDelete, setCheckDelete] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [planState, setPlanState] = useState(null)
    
    const [ openLoader, setOpenLoader] = useState(true)
    const [checkedValues, setCheckedValues] = useState({});

    useEffect(() => {
        setOpenLoader(true)
        requestInfoPlan();
        getInfoPlan().then(response =>{
            if(response && response.status === 200){
             response.response && response.response.planFeature && setPlanOwner(response.response.planFeature) 
             response.response.plan.time_plan && setTimePlans(response.response.plan.time_plan)
             response.response && response.response.plan && setPlan({...response.response.plan, features: response.response.planFeature}) 
             response.response && response.response.plan && setOriginalPlan({...response.response.plan, features: response.response.planFeature})
             response.response && response.response.planFeature.find(ele => ele.feature_id === 5 )  && setTotalUsers(response.response.planFeature.find(ele => ele.feature_id === 5).quantity)
             
            }
            setOpenLoader(false)
        })
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        let featuresPlan = {};
    }

    const handleChange = (event) => {
        const channelId = event.target.value;
        setSelectedChannel(channelId);
    };

    const handleRadioTimeChange = (event) => {
        setTimePlans(Number(event.target.value));
        createFeatures(Number(event.target.value), totalUsers);
    };
    const handleSubmitPlan = ()=>{
        if(plan.plan_value && originalPlan.plan_value) setOpenModal(true)
    }
    const handleCheckboxChangeNew = (feature)=>(event) => {
        const { name, checked, value } = event.target;   
        setCheckedValues({ ...checkedValues, [name]: checked });  
        let temporalCheckDelete = checkDelete
        temporalCheckDelete.includes(feature.id) ? delete temporalCheckDelete[temporalCheckDelete.indexOf(feature.id)] :temporalCheckDelete.push(feature.id)
        setCheckDelete( temporalCheckDelete)
        const newPlan = { ...plan };
       
        newPlan.plan_value = originalPlan.plan_value - 
        temporalCheckDelete.reduce((ant,des)=> 
        {if(planOwner.find(ele=> ele.id === des))return ant+planOwner.find(ele=> ele.id === des).unite_price
             return ant}, 0 )
        setPlan(newPlan);

    }
    const handleCheckboxChange = (event) => {
        const { name, checked, value } = event.target;
        setCheckedValues({ ...checkedValues, [name]: checked });

        const newPlan = { ...plan };
        const featureExists = newPlan.features.some(obj => obj.name === value);

        if (featureExists && !checked) {
            const filterFeature = newPlan.features.filter(obj => obj.name !== value);
            newPlan.features = filterFeature;
            newPlan.plan_value = addPrices(newPlan.features);
            setPlan(newPlan);
        } else {
            const filterFeature = features.find(obj => obj.name === value);
            const newFeature = { feature_id: filterFeature.id, name: filterFeature.name, quantity: 1, unite_price: filterFeature.price }
            newPlan.features.push(newFeature);
            newPlan.plan_value = addPrices(newPlan.features);
            setPlan(newPlan);
        }
    };

    function addPrices(features) {
        let total = 0;
        for (const feature of features) {
            total += feature.unite_price;
        }
        return total;
    }

    const createFeatures = (time, users) => {
        // ------------Create features-------------
        const filterForTime = infoPlans.listOne.find(obj => obj.value === time);
        const filterForUsers = filterForTime.features.find(obj => obj.totalUser === users).features;
        setFeatures(filterForUsers);

        // ------------Update plan-----------------
        const newPlan = { ...plan };
        const featureUser = filterForUsers.find(obj => obj.name === 'Usuario');

        newPlan.time_plan = time;
        newPlan.total_user = users;

        newPlan.features.map((feature) => {
            if (feature.name === "Usuario único" ) {
                feature.feature_id = featureUser.id;
                feature.quantity = users;
                feature.unite_price = featureUser.price;
            }
        });

        const total = addPrices(newPlan.features);
        newPlan.plan_value = total;
        setPlan(newPlan);
    }

    const handleAddUsers = (users) => {
        
        let newUsers = users;
        if (newUsers === 1) {
            setTotalUsers(5);
            newUsers = 5
        }
        createFeatures(timePlans, newUsers);
    }

    const handleRemoveUsers = (users) => {
        if(users%5!== 0)users= 5 * parseInt(users/5)-1
        let newUsers = users;
        if (users === 5) {
            setTotalUsers(1);
            newUsers = 1
        }
        createFeatures(timePlans, newUsers);
    }
    const handlerConfirm= ()=>{
        setOpenModal(false);
    }
    const [t, i18next] = useTranslation("global")

    return (
        <>
        { (<ModalDialogComponent
        view = {planStateSelect}
        tittle ={t(`board.adminSection.upgradePlan.planActions`)}
        message={t(`board.adminSection.upgradePlan.planDescription`)}
        render={ <><div style={styleCheck}>
        <div>
          
            <Checkbox
                checked={planState == 1}
                onChange={()=>setPlanState(1)}
                    //handleCheckboxChange}
                name={''}
                value={''}
                data-custom-value="myCustomValue"
                inputProps={{ 'aria-label': 'check' }}
            />
            <span  style={{
                    fontWeight: 400,
                    fontSize: 12,
                
                }}
            >{t(`board.adminSection.upgradePlan.actualPlan`)}</span>
             <Checkbox
                checked={planState == 2}
                onChange={()=>setPlanState(2)}
                    //handleCheckboxChange}
                name={''}
                value={'a'}
                data-custom-value="myCustomValue"
                inputProps={{ 'aria-label': 'check' }}
            />
            <span  style={{
                    fontWeight: 400,
                    fontSize: 12,
                
                }}
            >{t(`board.adminSection.upgradePlan.newPlan`)}</span>
        </div>
    </div></>}
        buttons={[
        {value:t(`board.adminSection.upgradePlan.continue`), color: "#7220db", onclick:()=>{ planState && setPlanStateSelect(false)}}
    ]}
        />)}
 
         <ModalDialogComponent
        view = {openModal}
        tittle ={t(`board.adminSection.planConfirm.confirm`)}
        message={t(`board.adminSection.planConfirm.description`)}
        render={ <Container style={{padding: 0 }}>
            <tr><th>
             <Grid item xs={12} md={12}>
                                    <Paper
                                    style={{paddingBottom: 5, paddingTop: 5, margin:0}}
                                        sx={{
                                            ml: { xs: 0, md: 4, },
                                            mt: { xs: 4, md: 0, },
                                            p: { xs: 4, md: 3, },
                                        }}
                                        elevation={3}
                                    >
                                        <h2 style={{ fontSize: 14 } }>{t(`board.adminSection.planConfirm.actualPlan`)}</h2>
                                        <Divider style={{ marginBottom: 3 }} />
                                       
                                        {
                                            originalPlan && originalPlan.features && originalPlan.features.map((feature, index) => (
                                                <React.Fragment key={index}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                        
                                                    }}
                                                    >
                                                       
                                                        {feature.name.toUpperCase().includes('Usuario'.toUpperCase())
                                                            ? <p style={{...styleText,color: checkDelete.includes(feature.id) ?'#FF0000': '#868686', textDecoration:checkDelete.includes(feature.id) &&"line-through"}}>{`${t(`board.adminSection.planSummary.${feature.name.toString().replace(/\s+/g, '')}`)} x ${feature.quantity}`}</p>
                                                            : <p style={{...styleText,color: checkDelete.includes(feature.id) ?'#FF0000': '#868686', textDecoration:checkDelete.includes(feature.id) &&"line-through"}}>{t(`board.adminSection.planSummary.${feature.name.toString().replace(/\s+/g, '')}`)}</p>
                                                           
                                                        }

                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize: 16,
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    marginRight: 0,
                                                                    color: checkDelete.includes(feature.id) ?'#FF0000':'#aeaeae'
                                                                }}
                                                            >
                                                                {
                                                                    feature.name === 'Canal' || feature.name === 'Vigencia'
                                                                        ? ('')
                                                                        : ('USD')
                                                                }
                                                            </span>

                                                            <span style={{ fontWeight: 700, fontSize: 14, color: checkDelete.includes(feature.id) ?'#FF0000': '#000000'}}>
                                                                {`$${Number(feature.unite_price).toLocaleString('en-US')}`}
                                                            </span>

                                                        </Typography>

                                                    </div>
                                                    <Divider style={{ marginBottom: 2, marginTop: 3 }} />
                                                </React.Fragment>
                                            ))
                                        }


                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        >

                                            <p style={
                                                {
                                                    color: '#333',
                                                    fontSize: '16px',
                                                    marginBottom: '10px',
                                                    marginTop: '10px',
                                                    fontWeight: 700,
                                                    textAlign: 'start'
                                                }
                                            }
                                            >{t(`board.adminSection.planConfirm.total`)}</p>

                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: 24,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        color: '#aeaeae'
                                                    }}
                                                >
                                                    USD
                                                </span>

                                                <span style={{ fontWeight: 700, fontSize: 24, color: '#f07d18' }}>
                                                    {`$${Number(originalPlan.plan_value).toLocaleString('en-US')}`}
                                                </span>

                                            </Typography>

                                        </div>
                                       
                                    </Paper>
                                </Grid> </th><th><Grid item xs={12} md={12}>
                                <Paper
                                    style={{paddingBottom: 5, paddingTop: 5, margin:0}}
                                        sx={{
                                            ml: { xs: 0, md: 4, },
                                            mt: { xs: 4, md: 0, },
                                            p: { xs: 4, md: 3, },
                                        }}
                                        elevation={3}
                                    >
                                        <h2 style={{ fontSize: 14 } }>{t(`board.adminSection.planConfirm.newPlan`)}</h2>
                                        <Divider style={{ marginBottom: 3 }} />
                                       
                                        {
                                            plan && plan.features && plan.features.map((plan, index) => (<>
                                         
                                                {!checkDelete.includes(plan.id) && (
                                                <React.Fragment key={index}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                        
                                                    }}
                                                    >
                                                       
                                                        {plan.name.toUpperCase().includes('Usuario'.toUpperCase())
                                                            ? <p style={{...styleText,}}>{`${t(`board.adminSection.planSummary.${plan.name.toString().replace(/\s+/g, '')}`)} x ${plan.quantity}`}</p>
                                                            : <p style={{...styleText,}}>{t(`board.adminSection.planSummary.${plan.name.toString().replace(/\s+/g, '')}`)}</p>
                                                           
                                                        }

                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize: 16,
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    marginRight: 0,
                                                                    color: '#aeaeae'
                                                                }}
                                                            >
                                                                {
                                                                    plan.name === 'Canal' || plan.name === 'Vigencia'
                                                                        ? ('')
                                                                        : ('USD')
                                                                }
                                                            </span>

                                                            <span style={{ fontWeight: 700, fontSize: 14, color: '#000000'}}>
                                                                {`$${Number(plan.unite_price).toLocaleString('en-US')}`}
                                                            </span>

                                                        </Typography>

                                                    </div>
                                                    <Divider style={{ marginBottom: 2, marginTop: 3 }} />
                                                </React.Fragment>
                                                )}</>
                                            ))
                                        }


                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        >

                                            <p style={
                                                {
                                                    color: '#333',
                                                    fontSize: '16px',
                                                    marginBottom: '10px',
                                                    marginTop: '10px',
                                                    fontWeight: 700,
                                                    textAlign: 'start'
                                                }
                                            }
                                            >{t(`board.adminSection.planConfirm.total`)}</p>

                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: 24,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        color: '#aeaeae'
                                                    }}
                                                >
                                                    USD
                                                </span>
                                                
                                                <span style={{ fontWeight: 700, fontSize: 24, color: '#f07d18' }}>
                                                    {`$${Number(plan.plan_value).toLocaleString('en-US')}`}
                                                </span>

                                            </Typography>

                                        </div>
                                       
                                    </Paper>
                                </Grid></th> </tr></Container>}
        buttons={[
        {value:t(`board.adminSection.planConfirm.cancel`), color: "rgb(133, 133, 133)", onclick: ()=>setOpenModal(false)},
        {value:t(`board.adminSection.planConfirm.request`), color: "#7220db", onclick:handlerConfirm}
    ]}
        />
            <Container 
            style={{padding:0, }}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={0} style={{ marginBottom: 35 , marginTop:-20}} maxWidth="xl">

                        {
                            openLoader &&
                           
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                            
                            ||
                            
                            <React.Fragment>
                                <Grid item xs={12} md={8} style={{ textAlign: 'left', marginTop:'-15px' }}>
                                    <h2 style={styleTitle}>{t("board.adminSection.planTable.section1")} {planState == 1 ?`(${t("board.adminSection.planTable.actualPlan")})`:planState == 1?'(Renovación de plan)':''}</h2>
                                    <p style={styleText}>{t("board.adminSection.planTable.section1Description")}</p>
                                    {/* <FormControl> */}
                                   
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={timePlans}
                                        onChange={handleRadioTimeChange}
                                    >
                                        <Grid container direction="row"
                                            justifyContent="center"
                                            alignItems="center" spacing={0}>
                                            {infoPlans.listOne ? infoPlans.listOne.map((time, index) => (
                                                <Grid item xs={6} md={3} key={index} style={{ textAlign: 'center', textSize:'5px' }} sx={{ mb: { xs: 2 } }}>
                                                   
                                                    <FormControlLabel   

                                                        value={time.value}
                                                        control={<Radio />}
                                                        label={`${time.value} ${t(`board.adminSection.planTable.${time.unit}`)}`}
                                                        style={styleRadio}
                                                        disabled={planState == 1}
                                                    />
                                                </Grid>

                                            ))

                                                : ''
                                            }
                                        </Grid>
                                    </RadioGroup>
                                    {/* </FormControl> */}

                                    <Divider style={{ marginBottom: 5, marginTop: 0 }} />

                                    <h2 style={styleTitle}>{t("board.adminSection.planTable.section2")}</h2>
                                    <p style={styleText}>{t("board.adminSection.planTable.section2Description")}</p>

                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        justifyContent="space-around"
                                        alignItems="stretch"
                                    >
                                        {
                                            features.length > 0 && features.map((feature, index) => (
                                                <Grid item xs={6} md={3} key={index} style={{ textAlign: 'center' }}>
                                                  
                                                    <CardHover variant="outlined">
                                                        <CardContent style={{marginTop:'-10px',padding:'1px', marginBottom: 'auto',  position: 'relative' }} sx={{height: {xs: 175, md: 150 }}}>
                                                            {index === 0 &&
                                                                <img
                                                                    src={Image1}
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                />
                                                            }

                                                            {index === 1 &&
                                                                <img
                                                                    src={Image2}
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                />
                                                            }

                                                            {index === 2 &&
                                                                <img
                                                                    src={Image3}
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                />
                                                            }

                                                            {index === 3 &&
                                                                <img
                                                                    src={Image4}
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                />
                                                            }

                                                            <Typography
                                                                variant="h3"
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontWeight: 700,
                                                                    color: '#4b4b4a',
                                                                    // marginTop: 20,
                                                                    // marginBottom: 10
                                                                }}
                                                                gutterBottom
                                                            >
                                                                {t(`board.adminSection.planTable.${feature.name.toString().replace(/\s+/g, '')}`)}
                                                                
                                                            </Typography>

                                                            {/* <Typography
                                                                variant="div"
                                                                style={{
                                                                    fontSize: 12,
                                                                    fontWeight: 400,
                                                                    color: '#868686',
                                                                    marginTop: 20,
                                                                    marginBottom: 10
                                                                }}
                                                            >
                                                                {feature.description}
                                                            </Typography> */}

                                                            <Typography
                                                                component="div"
                                                                style={{
                                                                    fontSize: 24,
                                                                    marginBottom: 0,
                                                                    position: 'absolute',
                                                                    bottom: -10,
                                                                    left: "50%",
                                                                    transform: "translateX(-50%)"
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight: 400,
                                                                        fontSize: 12,
                                                                        marginRight: 5,
                                                                        marginTop: 5,
                                                                        color: '#aeaeae'
                                                                    }}
                                                                >
                                                                    USD
                                                                </span>

                                                                <span style={{ fontWeight: 700, fontSize: 12, }}>
                                                                    {Number( planOwner.find( ele => ele.feature_id === feature.id) ? planOwner.find( ele => ele.feature_id === feature.id).unite_price: feature.price).toLocaleString('en-US')}
                                                                </span>
                                                                   
                                                            </Typography>

                                                        </CardContent>
                                                        <CardActions
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                position: 'asolute',
                                                                bottom: 0,
                                                                marginBottom: 10
                                                            }}
                                                        >

                                                            {
                                                                feature.name === 'Usuario' ?
                                                                    <div style={{ display: 'flex' }}>
                                                                        <IconButton aria-label="delete" onClick={() => handleRemoveUsers(totalUsers)
                                                                            } >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                border: '1px solid #858585',
                                                                                padding: '3px 15px 3px 15px',
                                                                                borderRadius: 3,
                                                                                backgroundColor: '#e6e6e6',
                                                                                color: '#858585'
                                                                            }}
                                                                        >
                                                                                                                        
                                                                            {totalUsers}
                                                                        </div>
                                                                        <IconButton aria-label="delete" onClick={() =>handleAddUsers(totalUsers)
                                                                        }>
                                                                            <AddIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                    :
                                                                    <div style={styleCheck}>
                                                                        <div>
                                                                          
                                                                            <Checkbox
                                                                                checked={(!Object.keys(checkedValues).includes(feature.name) && planOwner.find( ele => ele.feature_id === feature.id)) || checkedValues[feature.name] || false}
                                                                                onChange={handleCheckboxChangeNew(feature)}
                                                                                    //handleCheckboxChange}
                                                                                name={feature.name}
                                                                                value={feature.name}
                                                                                data-custom-value="myCustomValue"
                                                                                inputProps={{ 'aria-label': 'check' }}
                                                                            />
                                                                            <span  style={{
                                                                                    fontWeight: 400,
                                                                                    fontSize: 12,
                                                                                
                                                                                }}
                                                                            >{'Agregar'}</span>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </CardActions>
                                                    </CardHover>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Paper
                                    style={{paddingBottom: 10, paddingTop: 10}}
                                        sx={{
                                            ml: { xs: 0, md: 4, },
                                            mt: { xs: 4, md: 0, },
                                            p: { xs: 4, md: 3, },
                                        }}
                                        elevation={3}
                                    >
                                        <h2 style={{...styleTitle, } }>{t("board.adminSection.planSummary.orderSummary")}</h2>
                                        <Divider style={{ marginBottom: 3 }} />
                                       
                                        {
                                            plan && plan.features && plan.features.map((plan, index) => (
                                                <React.Fragment key={index}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                        
                                                    }}
                                                    >
                                      
                                                        {plan.name.toUpperCase().includes('Usuario'.toUpperCase())
                                                            ? <p style={{...styleText,color: checkDelete.includes(plan.id) ?'#FF0000': '#868686', textDecoration:checkDelete.includes(plan.id) &&"line-through"}}>{`${t(`board.adminSection.planSummary.${plan.name.toString().replace(/\s+/g, '')}`)} x ${plan.quantity}`}</p>
                                                            : <p style={{...styleText,color: checkDelete.includes(plan.id) ?'#FF0000': '#868686', textDecoration:checkDelete.includes(plan.id) &&"line-through"}}>{t(`board.adminSection.planSummary.${plan.name.toString().replace(/\s+/g, '')}`)}</p>
                                                           
                                                        }

                                                        <Typography
                                                            component="div"
                                                            style={{
                                                                fontSize: 16,
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    marginRight: 0,
                                                                    color: checkDelete.includes(plan.id) ?'#FF0000':'#aeaeae'
                                                                }}
                                                            >
                                                                {
                                                                    plan.name === 'Canal' || plan.name === 'Vigencia'
                                                                        ? ('')
                                                                        : ('USD')
                                                                }
                                                            </span>

                                                            <span style={{ fontWeight: 700, fontSize: 14, color: checkDelete.includes(plan.id) ?'#FF0000': '#000000'}}>
                                                                {`$${Number(plan.unite_price).toLocaleString('en-US')}`}
                                                            </span>

                                                        </Typography>

                                                    </div>
                                                    <Divider style={{ marginBottom: 2, marginTop: 3 }} />
                                                </React.Fragment>
                                            ))
                                        }


                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        >

                                            <p style={
                                                {
                                                    color: '#333',
                                                    fontSize: '16px',
                                                    marginBottom: '10px',
                                                    marginTop: '10px',
                                                    fontWeight: 700,
                                                    textAlign: 'start'
                                                }
                                            }
                                            >{t("board.adminSection.planSummary.total")}</p>

                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: 24,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 400,
                                                        fontSize: 18,
                                                        marginRight: 5,
                                                        color: '#aeaeae'
                                                    }}
                                                >
                                                    USD
                                                </span>

                                                <span style={{ fontWeight: 700, fontSize: 24, color: '#f07d18' }}>
                                                    {`$${Number(plan.plan_value).toLocaleString('en-US')}`}
                                                </span>

                                            </Typography>

                                        </div>
                                        <Divider style={{ marginBottom: 2, marginTop: 3 }} />

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        >
                                            <Typography
                                                component="div"
                                                style={{
                                                    fontSize: 12,
                                                    marginBottom: 15,
                                                    marginTop: 10,
                                                    color: 'inherit',
                                                    fontWeight: 500,
                                                    lineHeight: 1.1
                                                }}
                                            >
                                        {t("board.adminSection.planSummary.summaryDescription")}

                                            </Typography>

                                            {/* <Link
                                                to="/solicitarplan"
                                                state={{ plan }}
                                            > */}
                                                <Button
                                                    variant='outlined'
                                                    disabled={ (plan.plan_value == originalPlan.plan_value)}
                                                    type="submit"
                                                    onClick={handleSubmitPlan}
                                                    style={{
                                                        textTransform: 'none',
                                                        background: (plan.plan_value == originalPlan.plan_value) ? 'rgba(240,125,24,0.1)' : 'rgba(240,125,24,0.3)' ,
                                                        color: (plan.plan_value == originalPlan.plan_value) ?'rgba(240,125,24,0.5)': 'rgba(240,125,24,1)',
                                                        border: '1px solid #f07d18',
                                                        fontWeight: 700,
                                                        marginBottom: 10,
                                                        width: '100%',
                                                        borderRadius: 2,
                                                    }}
                                                >
                                                    {t("board.adminSection.planSummary.planAction")}
                                                </Button>
                                            {/* </Link> */}

                                        </div>
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </form>
            </Container >
            </>
            
        
    );
}

export default SelectPlan;