import * as React from 'react';
import { useNavigate } from "react-router-dom";
import  { useContext, useEffect  } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import {downloadExcel} from '../../actions/download';
import CaseList from './case-list';
import ListOptions from './list-options';
import ModalCases from './modal-cases';
import { useTranslation } from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 0,
                        height: "calc(100vh - 230px)"
                    }}
                >
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function FooterMenu(props) {
    const {
        filteredCasesMenu,setLayers, setFilteredCasesMenu, setIndexCaseOne, setIndexCaseTwo, setSecondCases, setListState
    } = useContext(UserContext);
    const arrayCases = props.arrayCases;
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        let menuCases = JSON.parse( localStorage.getItem('MenuCases'))
        setLayers({})
        let temporal = {}
        menuCases.map(row=>temporal[row._id]=false)
        setListState(temporal);
        setSecondCases([])
        setFilteredCasesMenu([...menuCases]);
        setIndexCaseOne({})
        setIndexCaseTwo({})
        newValue === 0 && (<Link to='/board'></Link>)
    };

    const [t, i18next] = useTranslation("global")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    function getGeneratorsInfo(linealDescription) {
        let generators = [];
        // const linealDescription = linealDescription;
        const generatorsList = linealDescription.pes.filter(pes => pes.name === 'generators');
        const initGeneratorsList = linealDescription.sensitivities.filter(sensitivity => sensitivity.title === 'iniGenerators');
        if (generatorsList && initGeneratorsList && generatorsList[0].values.length === initGeneratorsList[0].data.length) {
            for (let i = 0; i < generatorsList[0].values.length; i++) {
                const generator = {
                    name: '',
                    initGenerator: 0,
                }
                generator.name = generatorsList[0].values[i].trim();
                if (initGeneratorsList[0].data[i][2]) {
                    generator.initGenerator = initGeneratorsList[0].data[i][1] * initGeneratorsList[0].data[i][2];
                } else {
                    generator.initGenerator = initGeneratorsList[0].data[i][1];
                }
                generators.push(generator);
            }
        }
        return generators;
    }

    function calculatePowerPerPlant(linealDescription, generatorList) {
        let total;
        total = 0;
        if (generatorList && generatorList.length > 0) {
            generatorList.forEach(g => {
                if (g !== '') {
                    const generatorInfo = getGeneratorsInfo(linealDescription)
                        .filter(generator => generator.name === g.trim());
                    if (generatorInfo && generatorInfo.length > 0) {
                        total = total + generatorInfo[0].initGenerator;
                    }
                }
            });
        }
        return total;
    }


    function createRowsExcel(cases) {
        let listCases = [];

        cases.map(studyCase => {
            const data = [];
            const maxPowerPlant = [];
            const keyPowerPlant = [];

            data.push(['Nivel', 'Flexibilidad', 'Contingencia', 'Elemento', 'Limite', 'Criticidad']);

            const startPositionModel = data[0].length;

            studyCase.caseResult[0].layers.forEach(layer => {

                if (layer.constraints.length === 0) {
                    return [];
                } else {

                    layer.constraints.forEach((constraint, constraintIndex) => {

                        const rowConstrain = [
                            layer.level,
                            layer.flexibility,
                            constraint.contingency,
                            constraint.monitored,
                            constraint.limit,
                            constraint.ranking
                        ];

                        constraint.model.forEach((modelValue, jndex) => {

                            const generatorsName = modelValue.generatorName.replace(/\?./g, ' - ').replace(/\?$/g, '');
                            data[0][startPositionModel + jndex] = 'A' + jndex + ' (' + generatorsName + ')';

                            if (constraint.model.length - 1 === jndex) {
                                data[0][startPositionModel + jndex] = '<=';
                                data[0][startPositionModel + jndex + 1] = 'B';
                                rowConstrain.push('<=');
                            }

                            if (keyPowerPlant.indexOf(generatorsName) === -1 && generatorsName !== 'RHS') {
                                keyPowerPlant.push(generatorsName);
                                maxPowerPlant.push(calculatePowerPerPlant(studyCase.linealDescription[0], modelValue.generatorName.split('?')));
                            }
                            rowConstrain.push(modelValue.output);

                        });

                        data.push(rowConstrain);
                    })
                }
            })

            const rowMinPowerPlant = ['', '', '', '', '', 'Pmin Planta (MW)'];
            const rowMaxPowerPlant = ['', '', '', '', '', 'Pmax Planta (MW)'];
            maxPowerPlant.forEach(mpp => {
                rowMinPowerPlant.push('0');
                rowMaxPowerPlant.push(mpp);
            });
            data.push(rowMinPowerPlant);
            data.push(rowMaxPowerPlant);

            const caseComplete = {
                name: studyCase.caseResult[0].caseName,
                rows: data
            }

            listCases.push(caseComplete)
        })
        
        return listCases;
    }

    async function downloadExcelDocument() {
        try {
           
            const info = createRowsExcel(filteredCasesMenu)
            let data = {
                data:info,
                responseType: 'blob'
            }
            
            downloadExcel({data:info}).then(async  (response) => {
               
               
               if (response.blob){
                const url = window.URL.createObjectURL(new Blob([await response.blob()]));
            const link = document.createElement('a');
            link.href = url;
            const nameDocument = response.headers.get('Content-Disposition')
           
           const time = new Date()
            link.setAttribute('download', `${data.length == 1 && nameDocument && nameDocument || `Cases_${time.getTime()}.xlsx`}`);
            document.body.appendChild(link);
            link.click();
            }
            } )
            
            
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box sx={{ flex: 1 }}>
            <TabPanel value={value} index={0} >
                <Grid container spacing={0} >
                    <Grid item xs={8} style={{ borderTop: "0", borderBottom: "1px solid rgba(0, 0, 0, 0.12)", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                        {t("board.studyCases")}
                        <IconButton aria-label="fingerprint" color="primary" onClick={downloadExcelDocument} disabled = {filteredCasesMenu.length === 0 }>
                            <SimCardDownloadIcon style={{ color: filteredCasesMenu.length === 0 ? 'rgba(147,148,148,.5)':'#939494' }} />
                        </IconButton>

                        <ModalCases
                            open={open}
                            onOpen={handleClickOpen}
                            onClose={handleClose}
                            arrayCases={arrayCases}
                            filteredCases={props.filteredCases}
                            setFilteredCases={props.setFilteredCases}
                            setFilteredCasesMenu={props.setFilteredCasesMenu}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {t("board.flexibility")}
                    </Grid>
                </Grid>
                <CaseList
                    filteredCases={props.filteredCases}
                    setFilteredCases={props.setFilteredCases}
                    filteredLayers={props.filteredLayers}
                    setFilteredLayers={props.setFilteredLayers}
                />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Grid container spacing={0} >
                    <Grid
                        item
                        xs={12}
                        style={{
                            borderTop: "0",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: 10
                        }}
                    >
                        <b>{t("board.adminSection.adminSettings")}</b>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        < ListOptions />
                    </Grid>
                </Grid>
            </TabPanel>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
               
                <Tabs value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                >
                  
                 
                    <Tab  onClick={()=>navigate("/board")} icon={<StorageIcon />} {...a11yProps(0)} />
                        
                    {props.infoUser.admin && (<Tab icon={<SettingsIcon />} {...a11yProps(1)} />)}
                </Tabs>
                
            </Box>
        </Box>
    );
}