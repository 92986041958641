import React, { useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../contexts/context-layout-user';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function ModalCaseInformation() {
  const {
    handleCloseModalCaseInfo,
    openModalCaseInfo,
    nameToEdit,
    setNameToEdit,
    descriptionToEdit,
    setDescriptionToEdit,
    idCaseToEdit,
    handleClickNoti,
    setTypeOfNotification,
    setNotificationMessage,
    filteredCases,
    setFilteredCases
  } = useContext(UserContext);

  const [errorName, setErrorName] = React.useState('');
  const [errorDescriptio, setErrorDescription] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setLoading(false);
  };

  React.useEffect(() => {
  }, [filteredCases]);


  const patchData = async (data) => {
    try {
      setLoading(true);
      const id = idCaseToEdit; // Valor del parámetro id
      const url = `${process.env.BACKEND_URL}/api/v1/cases/${id}`;
      const response = await axios.patch(url, data);

      if (response.data.status === 200) {
        
        setFilteredCases(prevFilteredCases => {
          return prevFilteredCases.map(objeto => {
            if (objeto._id === response.data.response._id) {
              return response.data.response;
            }else {
              return objeto;
            }
            
          });
        });

        handleCloseModalCaseInfo();
        setLoading(false);
        setTypeOfNotification('success');
        setNotificationMessage('El caso fue editado con exito');
        handleClickNoti();
      }

    } catch (error) {
      console.error(error);
    }
  };

  const handlerSubmit = (event) => {
    event.preventDefault();

    let errors = [];

    if (!nameToEdit) {
      setErrorName('Por favor, ingresa un nombre');
      errors.push(1)
    } else {
    
      setErrorName('');
    }

    if (!descriptionToEdit) {
      setErrorDescription('Por favor, ingresa una descripción');
      errors.push(1)
    } else {
     
      setErrorDescription('');
    }

    console.log(errors);

    if (errors.length === 0) {
      let data = {
        name: nameToEdit,
        description: descriptionToEdit,
      }
      patchData(data);
    }
  }

  const handlerToGetName = (event) => {
    setNameToEdit(event.target.value);
  }

  const handlerToGetDescription = (event) => {
    setDescriptionToEdit(event.target.value);
  }

  return (
    <div>
      <Dialog open={openModalCaseInfo} onClose={handleCloseModalCaseInfo}>
        <DialogTitle>Información del caso</DialogTitle>
        <form onSubmit={handlerSubmit}>
          <DialogContent>


            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>


            <>
              <p
                style={{
                  color: 'red'
                }}
              >
                {errorName ? errorName : ''}
              </p>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nombre *"
                type="text"
                variant="outlined"
                value={nameToEdit}
                onChange={handlerToGetName}
                style={{
                  width: 350
                }}
              />

              <p
                style={{
                  color: 'red'
                }}
              >
                {errorDescriptio ? errorDescriptio : ''}
              </p>

              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Descripción *"
                type="text"
                fullWidth
                variant="outlined"
                value={descriptionToEdit ? descriptionToEdit : ''}
                onChange={handlerToGetDescription}
                style={{
                  width: 350
                }}
              />
            </>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalCaseInfo}>Cancelar</Button>
            <Button
              type='submit'
              startIcon={<BorderColorIcon />}
            > Editar</Button>
          </DialogActions>

        </form>
      </Dialog>
    </div>
  );
}