import * as React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AlarmIcon from '@mui/icons-material/Alarm';
import ListIcon from '@mui/icons-material/List';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import { loginrequest } from "../actions/users";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const plan = {
    creation_date: '',
    payment_date: '',
    start_date: '',
    end_date: '',
    time_plan: 1,
    status: '',
    plan_value: 1090,
    total_user: 1,
    is_demo: '',
    features: [
        { feature_id: 5, name: 'Usuario', quantity: 1, unite_price: 1090, }
    ],
};


export default function PhonoMenu(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color="secondary" aria-label="add an alarm" onClick={handleClickOpen} sx={{ display: { xs: 'block', md: 'none' }, mr: { xs: 2 } }} size="large">
                <MenuIcon style={{ color: 'rgb(70 0 151)' }} />
            </IconButton>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: 'rgb(70 0 151)' }}>
                    <Toolbar style={{ backgroundColor: 'rgb(70 0 151)' }}>

                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Menu
                        </Typography>

                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <List>
                    <Link to={'/'} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
                        <ListItem onClick={handleClose}>
                            <ListItemText primary="Inicio" />
                        </ListItem>
                    </Link>
                    <Divider />
                    <Link to={'/planesyservicios'} state={{ plan }} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
                        <ListItem onClick={handleClose}>
                            <ListItemText primary="Planes y servicios" />
                        </ListItem>
                    </Link>
                    <Divider />
                    <ListItem >
                        <ListItemText primary="Descargar" />
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <ListItemText primary="Contacto" />
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <ListItemText primary="Tutoriales" />
                    </ListItem>
                    <Divider />
                    <Link to={'/solicitardemo'} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
                        <ListItem button>
                            <ListItemText primary="Solicitar demo" />
                        </ListItem>
                    </Link>
                    <Divider />
                </List>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                    <Button
                        onClick={() => {
                            if (props.infoUser) { localStorage.removeItem('saxToken'); window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/logout` } else {

                                window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/login`
                                loginrequest().then()
                            }
                        }}
                        style={{
                            borderRadius: "32px",
                            backgroundColor: "#FF6A13",
                            border: "none",
                            color: "white",
                            padding: "10px 20px",
                            textDecoration: "none",
                            margin: "4px 2px",
                            cursor: "pointer",
                            width: "184px",
                            height: "42px",
                            textTransform: "none",
                            fontSize: 16
                        }}
                        startIcon={<PersonIcon />}
                    >
                        {props.infoUser ? "Cerrar sesión" : "Iniciar sesión"}
                    </Button>
                </div>
            </Dialog>
        </div >
    );
}