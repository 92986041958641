import Header from "../layouts/header";
import Title from "../components/title";
import RegisterForm from "../components/register/register-form";
import Footer from "../components/footer";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";

function Register(props) {
    return (
        <ContextPlansAndServicesProvider>
            <Header {...props}/>
            <Title title={'¡Para poder acceder al demo, es imprescindible que te registres previamente!'} />
            <RegisterForm />
            <Footer />
        </ContextPlansAndServicesProvider>
    );
}

export default Register;