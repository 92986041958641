import { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

import Image1 from '../../assets/icono_1.png';
import Image2 from '../../assets/icono_2.png';
import Image3 from '../../assets/icono_3.png';
import Image4 from '../../assets/icono_5.png';

const CardHover = styled(Card)({
    boxShadow: '0 2px 5px rgba(0,0,0,.3)',
    height: '100%',
    '&:hover': {
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.3s ease-in-out',
    },
})

const styleTitle = {
    fontSize: '20px',
    fontWeight: 700,
}

const styleText = {
    color: '#868686',
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '10px',
    fontWeight: 400,
    textAlign: 'start'
}

const styleRadio = {
    border: '1px solid grey',
    padding: '3px 10px 3px 10px',
    borderRadius: 3,
    marginRight: 20,
}

const styleCheck = {
    border: '1px solid grey',
    padding: '0 10px 0 10px',
    borderRadius: 3,
}


function ModalUpdatePlan() {
    const [selectedChannel, setSelectedChannel] = useState("");
    const [infoChannels, setInfoChannels] = useState([]);
    const [timePlans, setTimePlans] = useState([]);
    const [features, setFeatures] = useState([]);
    const [selectedTime, setSelectedTime] = useState(1);
    const [selectedFeaturePrice, setSelectedFeaturePrice] = useState([]);
    const [totalUsers, setTotalUsers] = useState(1);
    const [checkedValues, setCheckedValues] = useState({});
    const [prices, setPrices] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState([
        { name: 'Canal', channel_id: 1, channel_name: 'XM' },
        { name: 'Vigencia', time: 1, price: 0 },
        { name: 'Usuarios', users: 1, price: 1090 },
    ]);
    const [initialValues, setInitalValues] = useState([]);
    const [totalPago, setTotalPago] = useState(0);


    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${process.env.BACKEND_URL}/api/v1/plans/selectplan`);
            setInfoChannels(response.data.response.channels);
            setTimePlans(response.data.response.timePlans);
            setFeatures(response.data.response.features);
            setSelectedFeaturePrice(response.data.response.featurePrice);
            setPrices(response.data.response.featurePrice.filter(feature => feature.time_plan_id === 1));

            const initialPrice = response.data.response.featurePrice.filter(feature => feature.time_plan_id === 1);

            setTotalPago(initialPrice[3].value);

            const filteredArray = response.data.response.featurePrice.filter(obj => obj.feature_id === 5);
            const valuesArray = filteredArray && filteredArray.map(obj => obj.value);
            setInitalValues(valuesArray);
         

        };

        fetchData();
    }, []);

    const assignPriceValues = (value) => {
        const timePlanIds = {
            '1': 1,
            '3': 2,
            '6': 3,
            '12': 4,
        };

        const timePlanId = timePlanIds[value];
        const priceValues = selectedFeaturePrice.filter(feature => feature.time_plan_id === timePlanId);
        
        setPrices(priceValues);

    };

    const totalPayable = useMemo(() => {
        const totalPrice = selectedPlan.reduce((sum, obj) => {
            if (obj.price) {
                return sum + obj.price;
            }
            return sum;
        }, 0);
        return totalPrice;
    }, [selectedPlan]);

    const handleSubmit = (event) => {
        event.preventDefault();

        let featuresPlan = {};

        featuresPlan.channel_id = selectedChannel;
        featuresPlan.time = selectedTime;
        featuresPlan.functionalities = checkedValues;
    }

    const handleChange = (event) => {
        const channelId = event.target.value;
        const selectedChannelObj = infoChannels.find(channel => channel.id === channelId);
        setSelectedChannel(channelId);
        const existingIndex = selectedPlan.findIndex(plan => plan.name === 'Canal');
        if (existingIndex !== -1) {
            const newSelectedPlan = [...selectedPlan];
            newSelectedPlan[existingIndex] = { name: 'Canal', channel_id: channelId, channel_name: selectedChannelObj.name };
            setSelectedPlan(newSelectedPlan);
        } else {
            setSelectedPlan([...selectedPlan, { name: 'Canal', channel_id: channelId, channel_name: selectedChannelObj.name }]);
        }
      
    };

    const newTime = (filter, value) => {
        setSelectedPlan(prevSelectedPlan => {
            const timeIndex = prevSelectedPlan.findIndex(item => item.hasOwnProperty(filter));

            if (timeIndex !== -1) {
                const newSelectedPlan = [...prevSelectedPlan];
                newSelectedPlan[timeIndex] = { name: 'Vigencia', time: value, price: 0 };
                return newSelectedPlan;
            } else {
                return [...prevSelectedPlan, { name: 'Vigencia', time: value, price: 0 }];
            }
        });
    };

    const newTotalUsers = (filter, value) => {
        const timePlanIds = {
            '1': 1,
            '3': 2,
            '6': 3,
            '12': 4,
        };

        const timePlanId = timePlanIds[value];
        const priceValues = selectedFeaturePrice.filter(feature => feature.time_plan_id === timePlanId);

        setSelectedPlan(prevSelectedPlan => {
            const userIndex = prevSelectedPlan.findIndex(item => item.hasOwnProperty(filter));

            if (userIndex !== -1) {
                const newSelectedPlan = [...prevSelectedPlan];
                newSelectedPlan[userIndex] = { name: 'Usuarios', users: totalUsers, price: priceValues[3].value };
                return newSelectedPlan;
            } else {
                return [...prevSelectedPlan, { name: 'Usuarios', users: totalUsers, price: priceValues[3].value }];
            }
        });

        setTotalPago(priceValues[3].value);
        setTotalUsers(1);
    };

    const handleRadioTimeChange = (event) => {
        setSelectedTime(event.target.value);
        assignPriceValues(event.target.value);
        newTime('time', event.target.value);
        newTotalUsers('users', event.target.value);
       
    };


    const handleCheckboxChange = (event) => {
        const { name, checked, value } = event.target;
        setCheckedValues({ ...checkedValues, [name]: checked });
        setSelectedPlan([...selectedPlan, { name: name, value: checked, price: Number(value) }]);
    
    };

    const handleAddUsers = (pricio, total) => {
        let price;
        const sum = 5;
        if (total === 1) {
            setTotalUsers(prevTotal => prevTotal + 4);
            setTotalPago(prevTotal => prevTotal * 3);
            price = totalPago * 3;
           
        } else if (total >= 5) {
            setTotalUsers(prevTotal => prevTotal + sum);
            setTotalPago(prevTotal => prevTotal + 170);
            price = totalPago + 170;
           
        }

        const userIndex = selectedPlan.findIndex(item => item.hasOwnProperty('users'));

        if (userIndex !== -1) {
            const newSelectedPlan = [...selectedPlan];
            newSelectedPlan[userIndex] = { name: 'Usuarios', users: totalUsers, price: price };
            setSelectedPlan(newSelectedPlan);
        } else {
            setSelectedPlan([...selectedPlan, { name: 'Usuarios', users: totalUsers, price: price }]);
        }

    }

    const handleRemoveUsers = () => {
        const sum = 5;
        if (totalUsers <= 1) {
            setTotalUsers(1);

        } else if (totalUsers === 5) {
            setTotalUsers(totalUsers - 4);

        } else {
            setTotalUsers(totalUsers - sum);
        }

    }

    return (
        <>
            {/* <Container> */}
                <form onSubmit={handleSubmit} style={{padding: 25}}>
                    <Grid container spacing={0} style={{ marginBottom: 35 }} maxWidth="xl">
                        <Grid
                            item
                            xs={8}
                            style={{
                                textAlign: 'left'
                            }}
                        >
                            <h2 style={styleTitle}>1. Seleccione su canal</h2>
                            <p style={styleText}>Seleccione el canal que le brindó el acompañamiento en el proceso de compra</p>

                            <FormControl>
                                <InputLabel id="select">Canal</InputLabel>
                                <Select
                                    labelId="select"
                                    id="demo-simple-select"
                                    value={selectedChannel || 3}
                                    defaultValue={3}
                                    label="Canal"
                                    onChange={handleChange}
                                    sx={{ width: 300, marginBottom: 4 }}
                                >
                                    {infoChannels.filter((channel) => channel.id !== 1).map((channel) => (
                                        <MenuItem key={channel.id} value={channel.id}>{channel.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Divider style={{ marginBottom: 30 }} />

                            <h2 style={styleTitle}>2. Seleccione la vigencia de su plan</h2>
                            <p style={styleText}>La vigencia de su plan le puede ahorrar dinero, tenga en cuenta esto a la hora de seleccionar el tiempo que desea usar la aplicación</p>
                            <FormControl>
                                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedTime || 1}
                                    onChange={handleRadioTimeChange}
                                >
                                    {
                                        timePlans && timePlans.map((time, index) => (

                                            <div style={styleRadio}>
                                                <FormControlLabel
                                                    key={time.id}
                                                    value={time.value}
                                                    control={<Radio />}
                                                    label={`${time.value} ${time.unit}`}
                                                />
                                            </div>

                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>

                            <Divider style={{ marginBottom: 30, marginTop: 30 }} />

                            <h2 style={styleTitle}>3. Agregue los toppings de funcionalidades</h2>
                            <p style={styleText}>Los toppings son funcionalidades adicionales que puede tener la aplicación por lo cual tienen un costos mensual adicional al período de tiempo seleccionado previamente</p>

                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-around"
                                alignItems="stretch"
                            >
                                {
                                    features && features.map((feature, index) => (
                                        <Grid item xs={3} key={feature.id} style={{ textAlign: 'center' }}>
                                            <CardHover variant="outlined">
                                                <CardContent style={{ marginBottom: 'auto', height: 450, position: 'relative' }}>
                                                    {index === 0 &&
                                                        <img
                                                            src={Image1}
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    }

                                                    {index === 1 &&
                                                        <img
                                                            src={Image2}
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    }

                                                    {index === 2 &&
                                                        <img
                                                            src={Image3}
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    }

                                                    {index === 3 &&
                                                        <img
                                                            src={Image4}
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    }

                                                    <Typography
                                                        variant="h3"
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 700,
                                                            color: '#4b4b4a',
                                                            marginTop: 20,
                                                            marginBottom: 10
                                                        }}
                                                        gutterBottom
                                                    >
                                                        {feature.name}
                                                    </Typography>

                                                    <Typography
                                                        variant="div"
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: 400,
                                                            color: '#868686',
                                                            marginTop: 20,
                                                            marginBottom: 10
                                                        }}
                                                    // gutterBottom
                                                    >
                                                        {feature.description}
                                                    </Typography>

                                                    <Typography
                                                        component="div"
                                                        style={{
                                                            fontSize: 24,
                                                            marginBottom: 10,
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            left: "50%",
                                                            transform: "translateX(-50%)"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight: 400,
                                                                fontSize: 18,
                                                                marginRight: 5,
                                                                color: '#aeaeae'
                                                            }}
                                                        >
                                                            USD
                                                        </span>

                                                        <span style={{ fontWeight: 700, fontSize: 18, }}>
                                                            {
                                                                index === features.length - 1
                                                                    ? `$${totalPago}`
                                                                    : `$${prices[index].value}`
                                                            }
                                                        </span>

                                                    </Typography>

                                                </CardContent>
                                                <CardActions
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        position: 'asolute',
                                                        bottom: 0,
                                                        marginBottom: 10
                                                    }}
                                                >

                                                    {index === features.length - 1 ?
                                                        <div style={{ display: 'flex' }}>
                                                            <IconButton aria-label="delete">
                                                                <RemoveIcon onClick={handleRemoveUsers} />
                                                            </IconButton>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    border: '1px solid #858585',
                                                                    padding: '3px 15px 3px 15px',
                                                                    borderRadius: 3,
                                                                    backgroundColor: '#e6e6e6',
                                                                    color: '#858585'
                                                                }}
                                                            >
                                                                {totalUsers}
                                                            </div>
                                                            <IconButton aria-label="delete">
                                                                <AddIcon onClick={() => handleAddUsers(totalPago, totalUsers)} />
                                                            </IconButton>

                                                        </div>
                                                        :
                                                        <div style={styleCheck}>
                                                            <div key={feature.id}>
                                                                <Checkbox
                                                                    checked={checkedValues[feature.name] || false}
                                                                    onChange={handleCheckboxChange}
                                                                    name={feature.name}
                                                                    value={prices[index].value}
                                                                    data-custom-value="myCustomValue"
                                                                    inputProps={{ 'aria-label': 'check' }}
                                                                />
                                                                <span>{'Agregar'}</span>
                                                            </div>
                                                        </div>
                                                    }

                                                </CardActions>
                                            </CardHover>
                                        </Grid>

                                    ))
                                }

                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper style={{ padding: 20, marginLeft: 30 }} elevation={3}>
                                <h2 style={styleTitle}>Resumen de su pedido</h2>
                                <Divider style={{ marginBottom: 3 }} />

                                {
                                    selectedPlan && selectedPlan.map((plan, index) => (
                                        <>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                            >

                                                {plan.name === 'Usuarios'
                                                    ? plan.users ? <p style={styleText}>{`${plan.name} x ${totalUsers}`}</p> : <p style={styleText}>{plan.name}</p>
                                                    : plan.name === 'Vigencia'
                                                        ? <p style={styleText}>{`${plan.name} x mes`}</p>
                                                        : <p style={styleText}>{plan.name}</p>
                                                }

                                                <Typography
                                                    component="div"
                                                    style={{
                                                        fontSize: 24,
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 18,
                                                            marginRight: 5,
                                                            color: '#aeaeae'
                                                        }}
                                                    >
                                                        {
                                                            plan.name === 'Canal' || plan.name === 'Vigencia'
                                                                ? ('')
                                                                : ('USD')
                                                        }
                                                    </span>

                                                    <span style={{ fontWeight: 700, fontSize: 18, }}>
                                                        {
                                                            plan.name === 'Canal'
                                                                ? (`${plan.channel_name}`)
                                                                : plan.name === 'Vigencia'
                                                                    ? (`${plan.time}`)
                                                                    : (`$${plan.price}`)
                                                        }
                                                    </span>

                                                </Typography>

                                            </div>
                                            <Divider style={{ marginBottom: 2, marginTop: 3 }} />
                                        </>
                                    ))
                                }


                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                                >

                                    <p style={
                                        {
                                            color: '#333',
                                            fontSize: '16px',
                                            marginBottom: '10px',
                                            marginTop: '10px',
                                            fontWeight: 700,
                                            textAlign: 'start'
                                        }
                                    }
                                    >Total a pagar</p>

                                    <Typography
                                        component="div"
                                        style={{
                                            fontSize: 24,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 18,
                                                marginRight: 5,
                                                color: '#aeaeae'
                                            }}
                                        >
                                            USD
                                        </span>

                                        <span style={{ fontWeight: 700, fontSize: 24, color: '#f07d18' }}>
                                            {`$${totalPayable}`}
                                        </span>

                                    </Typography>

                                </div>
                                <Divider style={{ marginBottom: 2, marginTop: 3 }} />

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                                >
                                    <Typography
                                        component="div"
                                        style={{
                                            fontSize: 12,
                                            marginBottom: 30,
                                            marginTop: 10,
                                            color: 'inherit',
                                            fontWeight: 500,
                                            lineHeight: 1.1
                                        }}
                                    >
                                        Este valor no incluye impuestos, ni retenciones. No se tienen en cuenta estampillas, garantías extras, y otros temas financieros particulares de cada cliente.

                                    </Typography>

                                    <Button
                                        variant='outlined'
                                        type="submit"
                                        style={{
                                            textTransform: 'none',
                                            background: 'rgba(240,125,24,.1)',
                                            color: '#f07d18',
                                            border: '1px solid #f07d18',
                                            fontWeight: 700,
                                            marginBottom: 10,
                                            width: '100%',
                                            borderRadius: 2,
                                        }}
                                    >
                                        Iniciar compra
                                    </Button>

                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </form>

            {/* </Container> */}

        </>
    );
}

export default ModalUpdatePlan;