import Header from "../layouts/header";
import Title from "../components/title";
import Footer from "../components/footer";
import ContactForm from "../components/contact/contact-form";
import { useTranslation } from "react-i18next";



export default function Contacto(props) {
    const [t, i18next] = useTranslation("global")
    // let { state } = useLocation();
    // const plan = state.plan;

    return (

        <>
            <Header {...props}/>
            <Title title={'Contáctanos'} />
            <ContactForm {...props}/>
            <Footer />
            </>

    )
}