import Header from "../layouts/header";
import Title from "../components/title";
import RequestPlanForm from "../components/request-plan/request-plan-form";
import Footer from "../components/footer";
import Loader from "../components/loader";

import { ContextPlansAndServicesProvider } from "../contexts/contex-plans-and-services";

function RequestPlan(props) {
    return (
        <>
            <ContextPlansAndServicesProvider>
                <Header {...props}/>
                <Title title={'¡Para continuar, por favor diligencia la siguiente información!'} />
                <RequestPlanForm />
                <Footer />
                <Loader/>
            </ContextPlansAndServicesProvider>
        </>
    );
}

export default RequestPlan;