import React, { useContext } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import Button from '@mui/material/Button';
import { PhoneInput } from 'react-international-phone';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import {updateAddUserAdmin, adminGetUsers} from '../../actions/users';
import  ModalDialogComponent from '../../components/dialog/dialog-component'
import { useTranslation } from "react-i18next";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function ModalEditUser() {
    const {
        handleClickNoti,
        setTypeOfNotification,
        setNotificationMessage,
        setUsersAdmin,
        setListUsers
    } = useContext(UserContext);

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [lastName, setLasetName] = React.useState('');
    const [lastNameError, setLasetNameError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    const [checked, setChecked] = React.useState({ admin: false, analyst: false, developer: false });
    const [checkedError, setCheckedError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [modalMessages, setModalMessages] = React.useState(false);

    const [t, i18next] = useTranslation("global")
   
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const generatePasswordHash = async (pass) => {
        const saltRounds = 10;

        try {
            const salt = await bcrypt.genSalt(saltRounds);
            const hash = await bcrypt.hash(pass, salt);
            return hash;
        } catch (error) {
            console.error(error);
            // Manejar el error de generación del hash
            throw new Error('Error al generar el hash de la contraseña');
        }
    };

    const createUser = async (data) => {
        try {
            setLoading(true);
            updateAddUserAdmin({data:data}).then(response => {
               
            if (response.status === 200) {
                adminGetUsers().then(response => {
                    response && response.response && setListUsers(response.response
                        .map(row=> ({...row, rolName: row.user_type === 1 ? "admin": row.user_type === 2 ?  "analyst" : row.user_type === 4 ? "developer" : ""  })))
                })
                setLoading(false);
                
                setName('')
                setLasetName('')
                setEmail('')
                setPhoneNumber('')
                setChecked({ admin: false, analyst: false, developer: false });
                handleClose();
                setTypeOfNotification('success');
                setNotificationMessage('El usuario se creó correctamente');
                handleClickNoti();

                
            }
            else if(response.status === 500){
                setModalMessages(true);
                setLoading(false);
            } 
        })

        } catch (error) {
            console.error('Error al actualizar la información del usuario:', error);
        }
    };


    const handleSubmit = async (e) => {
       
        e.preventDefault();

        let errors = [];

        if (!name || name === '') {
            errors.push(1);
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (!lastName || lastName == '') {
            errors.push(1);
            setLasetNameError(true);
        } else {
            setLasetNameError(false);
        }

        if (!email || email == '') {
            errors.push(1);
            setEmailError(true);
        } else {
            setEmailError(false);
        }

        if (!phoneNumber || phoneNumber.length < 4) {
            errors.push(1);
            setPhoneNumberError(true);
            return 
        } else {
            setPhoneNumberError(false);
        }

        
        if ((!checked.admin && !checked.analyst && !checked.developer) || Object.values(checked).every(ele=>ele)) {
            errors.push(1);
            setCheckedError(true);
        } else {
            setCheckedError(false);
        }

        
        if (errors.length === 0) {
            let data = {};

            data.name = name.toLowerCase();
            data.lastname = lastName.toLowerCase();
            data.email = email.toLowerCase();
            data.phone_number = phoneNumber.toLowerCase();
            data.roles = checked;

            createUser(data);
        }

    }

    const handleGetName = (e) => {
        setName(e.target.value);
    }

    const handleGetLastName = (e) => {
        setLasetName(e.target.value);
    }

    const handleGetEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleGetPhoneNumber = (e) => {
        setPhoneNumber(e);
    }

    const handleGetPassword = (e) => {
        setPassword(e.target.value);
    }

    const handleGetConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleGetChecked = (e) => {
        const { name, checked: isChecked } = e.target;
        const names = ['analyst', 'admin',"developer"]
        
        Object.keys(checked).forEach(key => {
            checked[key] = (key === name) && isChecked;
          });

        setChecked((prevState) => ({
        ...prevState,
        [name]: isChecked
        }));
    }

    return (
        <div>
            <ModalDialogComponent
        view = {modalMessages}
        tittle ={t("errors.user.title")}
        message={t("errors.user.description")}
        buttons={[
            {value:t("errors.user.accept"),onclick:()=>{setModalMessages(false)}}
        ]}
        />
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                startIcon={<GroupAddIcon />}
                size="small"
            >
                {t("board.adminSection.usersTable.addUser")} mas
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'xs'}
                TransitionComponent={Transition}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{t("board.adminSection.usersTable.addUser")}</DialogTitle>
                    <DialogContent dividers>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                            onClick={handleClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label={t(`board.adminSection.userAddition.name`)+  " *"} 
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={name}
                                    onChange={handleGetName}
                                    size="small"
                                    error={nameError}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label={t(`board.adminSection.userAddition.lastName`)+  " *"} 
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={lastName}
                                    onChange={handleGetLastName}
                                    size="small"
                                    error={lastNameError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label={t(`board.adminSection.userAddition.email`)+  " *"} 
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                    value={email}
                                    onChange={handleGetEmail}
                                    size="small"
                                    error={emailError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <PhoneInput
                                    label={t(`board.adminSection.userEdition.phone`)+  " *"} 
                                    id='phone'
                                    size="small"
                                    defaultCountry='co'
                                    countryEditable={false}
                                    value={phoneNumber}
                                    onChange={handleGetPhoneNumber}
                                    style={{
                                        fontSize: '0.75rem',
                                        fontWeight: 400,
                                        minWidth: '352px'
                                      
                                    }}
                                    error={phoneNumberError}
                                    
                                    />
                                {/* <TextField
                                    margin="dense"
                                    label={t(`board.adminSection.userAddition.phone`)+  " *"} 
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={phoneNumber}
                                    // onChange={handleGetPhoneNumber}
                                    size="small"
                                    error={phoneNumberError}
                                /> */}
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Contraseña *"
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    value={password}
                                    onChange={handleGetPassword}
                                    size="small"
                                    error={passwordError}
                                    helperText={messageError ? 'Las contraseñas deben coinsidir' : ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="dense"
                                    label="Confirmar contraseña *"
                                    type="password"
                                    fullWidth
                                    variant="outlined"
                                    value={confirmPassword}
                                    onChange={handleGetConfirmPassword}
                                    size="small"
                                    error={confirmPasswordError}
                                    helperText={messageError ? 'Las contraseñas deben coinsidir' : ''}
                                />
                            </Grid> */}
                        </Grid>

                        <p style={{ marginBottom: 0, color: checkedError && 'red' }}>{t(`board.adminSection.userAddition.roles`) +  " *"}</p>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.admin}
                                        onChange={handleGetChecked}
                                        name="admin"
                                    />
                                }
                                label={t(`board.adminSection.userAddition.admin`)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.analyst}
                                        onChange={handleGetChecked}
                                        name="analyst"
                                    />
                                }
                                label={t(`board.adminSection.userAddition.analyst`)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.developer}
                                        onChange={handleGetChecked}
                                        name="developer"
                                    />
                                }
                                label={t(`board.adminSection.userAddition.developer`)}
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t(`board.adminSection.userAddition.cancel`)}</Button>
                        <Button type='submit'>{t(`board.adminSection.userAddition.add`)}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}