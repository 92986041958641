import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function ModalTermsAndConditions() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => () => {
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <b
                onClick={handleClickOpen('paper')}
                style={{
                    marginLeft: 5,
                    marginRight: 5,
                    cursor: 'pointer'
                }}
            >
                términos y condiciones
            </b>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="scroll-dialog-title">términos y condiciones
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <div>
                        <div class="modal-body">
    <div id="box" class="form-control">

      <p style={{textAlign: "justify"}}><strong>SERVICIOS DE AN&Aacute;LISIS GEOM&Eacute;TRICO DE SISTEMAS DE POTENCIA (SAX)</strong></p>
<p style={{textAlign: "justify"}}><strong>UNA VEZ ACEPTADA LA PROPUESTA COMERCIAL O INICIADA LA EJECUCI&Oacute;N DE LOS SERVICIOS O RECIBIDA LA FACTURA DE LOS MISMOS O DESCARGADO CUALQUIER INFORME, EL CLIENTE ACEPTA LOS PRESENTES T&Eacute;RMINOS Y CONDICIONES DE SERVICIOS DE AN&Aacute;LISIS GEOM&Eacute;TRICO DE SISTEMAS DE POTENCIA (en adelante &ldquo;servicios SAX&rdquo;).</strong></p>
<p style={{textAlign: "justify"}}><strong>XM S.A. E.S.P. ENTIENDE QUE LA PERSONA QUE SUSCRIBE LA ORDEN DE SERVICIOS U CUALQUIER COMUNICACI&Oacute;N QUE CONTENGA LA ACEPTACI&Oacute;N A LA PROPUESTA COMERCIAL O ACEPTA LA SUSCRIPCI&Oacute;N DE ESTOS T&Eacute;RMINOS Y CONDICIONES, POSEE LAS FACULTADES PARA OBLIGAR AL CLIENTE.</strong></p>
<p style={{textAlign: "justify"}}>Los servicios SAX se presentan por parte de XM S.A. E.S.P. (en adelante &ldquo;XM&rdquo;) al Cliente (en adelante &ldquo;Usted&rdquo;) - quienes conjuntamente se denominar&aacute;n las Partes &ndash; regido por los siguientes t&eacute;rminos y condiciones que regir&aacute;n las &Oacute;rdenes de Servicios que Usted presente a XM y cualquier uso que realice de los servicios SAX.</p>
<p style={{textAlign: "justify"}}><strong>1. DEFINICIONES</strong></p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>&Oacute;rdenes de Servicio&rdquo;&nbsp;</strong>son las solicitudes de servicio realizados por Usted a XM, despu&eacute;s de la cotizaci&oacute;n o propuesta comercial, y que hace relaci&oacute;n a los Servicios .</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Per&iacute;odo de Servicios&rdquo;&nbsp;</strong>se refiere al per&iacute;odo de tiempo por el cual Usted ha solicitado Servicios en la Nube seg&uacute;n lo detallado en su orden.</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Planes de servicios&rdquo;&nbsp;</strong>se refiere a la combinaci&oacute;n de componentes de hardware y software pertenecientes, licenciados o administrados por XM, y respecto de los cuales XM les brinda acceso a Usted y a Sus Usuarios como parte de los Servicios en la Nube que Usted ha solicitado.</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Programa Complementario&rdquo;&nbsp;</strong>significa las herramientas que se requieren descargar a efectos de facilitar el acceso, operaci&oacute;n y/o uso de los Servicios SAX. Luego, los servicios SAX no incluye el licenciamiento que se requiera adquirir de Tecnolog&iacute;a de Terceros.</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Renovar Autom&aacute;ticamente&rdquo;&nbsp;</strong>o&nbsp;<strong>&ldquo;Renovaci&oacute;n Autom&aacute;tica&rdquo;&nbsp;</strong>es el proceso por el cual el per&iacute;odo de servicios SAX en virtud de una nueva orden se prorroga autom&aacute;ticamente por un per&iacute;odo adicional, salvo que dichos servicios SAX se terminen por otra causa de conformidad con los t&eacute;rminos de la orden.</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Servicios SAX&rdquo;&nbsp;</strong>son los planes de servicio ofrecidos por XM.</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Servicios en la Nube&rdquo;&nbsp;</strong>significa, en conjunto, los servicios SAX en la nube de XM (y definidos en las especificaciones de los planes de servicio. El t&eacute;rmino &ldquo;Servicios en la Nube&rdquo; no incluye otros servicios no especificados en los planes.</p>
<p style={{textAlign: "justify"}}>&ldquo;<strong>Usuarios&rdquo;&nbsp;</strong>significa los empleados, contratistas y usuarios finales, seg&uacute;n corresponda, autorizados por Usted o en Su nombre para utilizar los Servicios en la Nube de conformidad con estos t&eacute;rminos y condiciones.</p>
<p style={{textAlign: "justify"}}><strong>2. SERVICIOS</strong></p>
<p style={{textAlign: "justify"}}>XM proveer&aacute; a Usted los servicios SAX de acuerdo a los planes de servicios. Las Partes durante la vigencia de los servicios no podr&aacute;n cancelar los planes de servicio y por lo tanto no habr&aacute; lugar a devoluci&oacute;n de dinero, no obstante, los podr&aacute; prorrogar o incrementar de un plan u otro en cualquier momento, sin embargo, el ajuste a planes con funcionalidades o tiempos inferiores a los contratados s&oacute;lo podr&aacute; realizarse al momento del vencimiento del respectivo plan.</p>
<p style={{textAlign: "justify"}}><strong>3. VERSI&Oacute;N DE PRUEBA O VERSI&Oacute;N DEMO DE LOS SERVICIOS SAX</strong></p>
<p style={{textAlign: "justify"}}>XM podr&aacute; ofrecer una versi&oacute;n de prueba de los servicios SAX, con la finalidad que Usted pueda evaluar las funcionalidades de estos, esto es, para utilizar con fines de prueba, y no en producci&oacute;n. La versi&oacute;n de prueba se proporciona &ldquo;en el estado en que se encuentran&rdquo; y &ldquo;en el estado en que se encuentran disponibles&rdquo;</p>
<p style={{textAlign: "justify"}}>Esta versi&oacute;n de prueba ser&aacute; con las caracter&iacute;sticas del plan defina XM y por un tiempo restringido o limitado que igualmente determine, todo lo cual le ser&aacute; informado en la propuesta comercial.</p>
<p style={{textAlign: "justify"}}>XM no se hace responsable del almacenamiento de la informaci&oacute;n en la versi&oacute;n de prueba, la cual podr&aacute; ser eliminada y por lo tanto, no tendr&aacute; la obligaci&oacute;n de conservar copia alguna de la misma.</p>
<p style={{textAlign: "justify"}}>En cuanto a las funcionalidades de la versi&oacute;n prueba, las mismas podr&aacute;n variar de aquellas ofrecidas en cada uno de los planes ofrecidos.</p>
<p style={{textAlign: "justify"}}>La versi&oacute;n de prueba no tendr&aacute; costo para Usted.</p>
<p style={{textAlign: "justify"}}><strong>4. PRECIO, FORMA DE PAGO Y PER&Iacute;ODO DE FACTURACI&Oacute;N</strong></p>
<p style={{textAlign: "justify"}}><strong>4.1</strong>&nbsp;XM se obliga a informar el precio de cada uno de los planes de servicios.</p>
<p style={{textAlign: "justify"}}><strong>4.2</strong>&nbsp;El pago de los planes de servicios adquiridos se facturar&aacute; de manera anticipada de acuerdo al plan seleccionado. La factura se emitir&aacute; dentro de los cinco (5) d&iacute;as h&aacute;biles siguientes al momento de recibir la orden de servicios, la cual ser&aacute; pagada por los medios que disponga XM. S&oacute;lo una vez se confirme el pago, XM habilitar&aacute; el uso de los servicios SAX.</p>
<p style={{textAlign: "justify"}}><strong>4.3&nbsp;</strong>En el caso de que haya una modificaci&oacute;n de las tarifas de los planes de servicios por parte de XM, una vez notificado este cambio, si Usted no rechaza la variaci&oacute;n solicitando la baja del servicio, se entender&aacute; que acepta las nuevas tarifas.</p>
<p style={{textAlign: "justify"}}><strong>4.5</strong>&nbsp;Usted deber&aacute; pagar los impuestos sobre las ventas, valor agregado u otros impuestos similares exigidos por la ley aplicable que XM deba pagar en funci&oacute;n de los servicios SAX.</p>
<p style={{textAlign: "justify"}}><strong>5. PER&Iacute;ODO DE SERVICIOS</strong></p>
<p style={{textAlign: "justify"}}>Los planes de servicios ser&aacute;n para los per&iacute;odos establecidos en cada uno de ellos conforme a las &oacute;rdenes que lo acompa&ntilde;an.</p>
<p style={{textAlign: "justify"}}><strong>6. DERECHOS OTORGADOS</strong></p>
<p style={{textAlign: "justify"}}><strong>6.1</strong>&nbsp;Durante el per&iacute;odo de servicios y sujeto a sus obligaciones de pago, y salvo disposici&oacute;n en contrario contenida en los presentes t&eacute;rminos y condiciones o en la orden de servicios, Usted dispondr&aacute; de un derecho usar los servicios SAX, accesibles desde cualquier parte, no transferible o cedible, no exclusivo y limitado a los usos, restricciones y limitaciones de estos. Usted s&oacute;lo tendr&aacute; acceso a los servicios SAX desde el dominio del sitio web que XM ha dispuesto.</p>
<p style={{textAlign: "justify"}}>Los planes de servicio estar&aacute;n limitados exclusivamente para sus operaciones de negocios internas, y no podr&aacute; usarlos para comercializar los planes de servicios.</p>
<p style={{textAlign: "justify"}}>Usted no adquiere en virtud de estos t&eacute;rminos y condiciones derecho o licencia alguno para utilizar los servicios SAX, que excedan el alcance y/o la duraci&oacute;n de los planes de servicios indicados en su orden. Una vez finalizados los planes de servicios solicitados, su derecho de acceso y uso de los servicios terminar&aacute;.</p>
<p style={{textAlign: "justify"}}>Usted podr&aacute; permitir que sus Usuarios utilicen los servicios SAX y Usted ser&aacute; responsable de que dicho uso se haga de conformidad con los presentes t&eacute;rminos y condiciones.</p>
<p style={{textAlign: "justify"}}><strong>6.2</strong>&nbsp;Durante el per&iacute;odo de los servicios, Usted estar&aacute; exento del pago de regal&iacute;as por desarrollos realizados por XM y entregados a Usted como parte de los servicios SAX.</p>
<p style={{textAlign: "justify"}}><strong>6.3</strong>&nbsp;Usted tiene derecho a recibir el soporte t&eacute;cnico o administrativo/comercial, para cada uno de los planes en el horario laboral de XM. No se considerar&aacute;n los s&aacute;bados, domingos y festivos oficiales en Colombia. Los servicios de mantenimiento, actualizaciones y resoluci&oacute;n de errores se realizar&aacute;n de forma remota. Estos servicios no incluyen desplazamientos a sus instalaciones.</p>
<p style={{textAlign: "justify"}}><strong>6.4</strong>&nbsp;No existe permanencia ni obligaci&oacute;n de la misma por parte de Usted. Luego, en cualquier momento, podr&aacute; solicitar la cancelaci&oacute;n del plan de servicio adquirido a trav&eacute;s de los canales que se especifican en la web. La cancelaci&oacute;n se har&aacute; efectiva dentro de los cinco (5) d&iacute;as h&aacute;biles siguientes al recibo de la solicitud o antes al vencimiento del per&iacute;odo del servicio. La cancelaci&oacute;n conlleva la eliminaci&oacute;n de sus Bases de Datos a partir de las 48 horas posteriores a solicitud de cancelaci&oacute;n. Usted tiene el derecho de solicitar copia de su base de datos en este tiempo, lo cual no aplicar&aacute; para la versi&oacute;n de prueba.</p>
<p style={{textAlign: "justify"}}><strong>6.5</strong>&nbsp;Si XM cancela el servicio prestado sin que Usted cometa infracci&oacute;n alguna de las condiciones aqu&iacute; descritas, le ser&iacute;a devuelto el importe correspondiente a la parte proporcional del per&iacute;odo no consumido. En cualquier caso, XM no ser&aacute; responsable por los da&ntilde;os y perjuicios de las consecuencias que puedan derivarse de la interrupci&oacute;n del servicio.</p>
<p style={{textAlign: "justify"}}>&nbsp;7.&nbsp;<strong>ACUERDO DE NIVEL DE SERVICIO SAX (ANS).</strong></p>
<p style={{textAlign: "justify"}}>Este Acuerdo de Nivel de Servicio de SAX (Tambi&eacute;n SLA, por sus siglas en ingl&eacute;s) especifica las condiciones que acepta el consumidor final. Es una pol&iacute;tica que rige el uso de los Servicios Incluidos, que se mencionan a continuaci&oacute;n, y aplica de manera independiente a cada cuenta que utiliza los Servicios Incluidos. En caso de conflicto entre las condiciones de este SLA y los t&eacute;rminos y condiciones del cliente de SAX u otro acuerdo celebrado que rija el uso de los Servicios mencionados en el numeral 2, se aplican los t&eacute;rminos y condiciones de este SLA. Es importante recalcar que as&iacute; ser&aacute; s&oacute;lo en la medida de tal conflicto.</p>
<p style={{textAlign: "justify"}}>En el Contrato o Acuerdos de Niveles de Servicio se describe y define los siguientes aspectos:</p>
<ul style={{textAlign: "justify"}}>
<li>Compromiso de prestaci&oacute;n de un servicio</li>
<li>Niveles de atenci&oacute;n con el cliente (tiempo y horarios)</li>
<li>Procedimiento econ&oacute;mico durante la vigencia o cancelaci&oacute;n del servicio</li>
<li>La disponibilidad de la plataforma y el acceso en cualquier momento</li>
<li>Gesti&oacute;n y manejo de errores de la plataforma</li>
<li>Gesti&oacute;n para la seguridad de los datos</li>
</ul>
<p>&nbsp;</p>
<p style={{textAlign: "justify"}}><strong>COMPROMISO DE PRESTACI&Oacute;N DE UN SERVICIO</strong></p>
<p style={{textAlign: "justify"}}>SAX realizar&aacute; sus mejores esfuerzos para mantener la disponibilidad y continuidad de los Servicios con ocasi&oacute;n de la normatividad y/o regulaci&oacute;n que se expida; luego, en el caso que exista alg&uacute;n impedimento en estas &uacute;ltimas o que haga m&aacute;s gravosa la prestaci&oacute;n de los Servicios, SAX revisar&aacute; los tipos de inconvenientes, y de acuerdo a los t&eacute;rminos y condiciones establecidos, implementar&aacute; la soluci&oacute;n ideal para el usuario final.</p>
<p style={{textAlign: "justify"}}>Niveles de atenci&oacute;n con el cliente</p>
<p style={{textAlign: "justify"}}>La comunicaci&oacute;n con el cliente para cualquier petici&oacute;n, queja o reclamo ser&aacute; a trav&eacute;s del correo electr&oacute;nico: <a href="mailto:info@gaps-online.com">info@gaps-online.com</a>. Se deber&aacute; tener una respuesta por parte de SAX entre 1 a 10 d&iacute;as h&aacute;biles posteriores al requerimiento. Se reconoce como respuesta el contacto, bien sea para informar sobre la soluci&oacute;n, ampliar su conocimiento sobre el inconveniente presentado o informar sobre los procedimientos a seguir en b&uacute;squeda de una soluci&oacute;n.</p>
<p style={{textAlign: "justify"}}>Los servicios tal y como se encuentran dise&ntilde;ados en la plataforma pueden estar sujetos a modificaciones y ajustes, de forma tal que dichos cambios no requieren aceptaci&oacute;n expresa de los Usuarios, toda vez que se entienden incorporados y aceptados con su uso. Cualquier modificaci&oacute;n e, incluso, la restricci&oacute;n o suspensi&oacute;n programada temporal o permanente de los Servicios, ser&aacute; avisada al cliente por correo electr&oacute;nico con 3 d&iacute;as h&aacute;biles de antelaci&oacute;n. A su vez, para procesos de registro o de retiro ya iniciados, o que hubieren terminado, los cambios en estos t&eacute;rminos y condiciones podr&aacute;n ser acogidos o no por el cliente.</p>
<p style={{textAlign: "justify"}}><strong>PROCEDIMIENTO ECON&Oacute;MICO DURANTE LA VIGENCIA O CANCELACI&Oacute;N DEL SERVICIO</strong></p>
<p style={{textAlign: "justify"}}>Los Usuarios de SAX, durante la vigencia del servicio, no podr&aacute;n solicitar la devoluci&oacute;n del dinero pagado. En el caso en que se verificase que ocurri&oacute; una falla en la cual la afectaci&oacute;n del cliente final es atribuible a SAX, se realizar&aacute; la compensaci&oacute;n en saldo para registrar en la plataforma, equivalente al cobro del registro por la cantidad de certificados totales implicados en la falla. Para acceder a esta devoluci&oacute;n, el cliente debe presentar las evidencias en las cuales se constate que la falla es atribuible a SAX, y no a cualquier otro actor implicado en la cadena de valor o externo que interact&uacute;e con la plataforma.</p>
<p style={{textAlign: "justify"}}>El pago de los Servicios se realizar&aacute; acorde a lo establecido en la plataforma en l&iacute;nea por parte de SAX.</p>
<p style={{textAlign: "justify"}}><strong>LA DISPONIBILIDAD DE LA PLATAFORMA Y EL ACCESO EN CUALQUIER MOMENTO </strong></p>
<p style={{textAlign: "justify"}}>El acceso a los Servicios s&oacute;lo se podr&aacute; realizar por la plataforma que disponga SAX</p>
<p style={{textAlign: "justify"}}>La accesibilidad a la plataforma se asegura desde cualquier sistema computacional con acceso a internet, y bajo el uso de los navegadores Google Chrome, Mozilla Firefox y Microsoft Edge, en cumplimiento con los est&aacute;ndares requeridos por las &uacute;ltimas actualizaciones realizadas por cada uno de estos sistemas.</p>
<p style={{textAlign: "justify"}}>El cliente se compromete a suministrar toda la informaci&oacute;n que se solicite &uacute;nicamente dentro de la plataforma dispuesta por SAX. Luego, SAX no se hace responsable por la informaci&oacute;n suministrada en los enlaces o hiperv&iacute;nculos de terceras personas.</p>
<p style={{textAlign: "justify"}}>SAX se reserva el derecho a negar o retirar el acceso a los Servicios a cualquier Usuario por cualquier motivo y/o a los contenidos de este, con un aviso de m&iacute;nimo 3 d&iacute;as h&aacute;biles de antelaci&oacute;n a trav&eacute;s de correo electr&oacute;nico, por iniciativa propia o a petici&oacute;n de cualquier persona, incluyendo sin limitarse a aquellos usuarios que den un uso indebido a los servicios, as&iacute; como a los usuarios que incumplan total o parcialmente los T&eacute;rminos y Condiciones y estos Acuerdos de Niveles de Servicio. En especial, se podr&aacute; negar el retiro o acceso a los Servicios, en el evento que el usuario se encuentre en causal alguna de cancelaci&oacute;n o suspensi&oacute;n de la(s) licencia(s) y permisos que a la fecha le han sido expedidos para el ejercicio de sus actividades y con ello se est&eacute; generando un riesgo a SAX o los destinarios o beneficiarios de la informaci&oacute;n.</p>
<p style={{textAlign: "justify"}}>En el momento que el usuario elimine la cuenta, se mantendr&aacute; disponible la informaci&oacute;n de trazabilidad requerida para mantener la integridad de la plataforma. Por otro lado, se eliminar&aacute;n los datos personales o de la empresa no requeridos para la trazabilidad.</p>
<p style={{textAlign: "justify"}}><strong>GESTI&Oacute;N Y MANEJO DE ERRORES DE LA PLATAFORMA.</strong></p>
<p style={{textAlign: "justify"}}>Las fallas que se atienden bajo este sistema son espec&iacute;ficamente de operaci&oacute;n de la plataforma, no errores de uso por parte del cliente. Asimismo, las mejoras sugeridas deben ser tratadas con el equipo comercial de forma directa antes de planear la implementaci&oacute;n. La disponibilidad del sistema depende directamente de la disponibilidad de los servicios en la nube de AWS.</p>
<p style={{textAlign: "justify"}}>Para fines de atenci&oacute;n en el soporte se tienen los siguientes tiempos:</p>

<table 
//style="border-collapse:collapse;border:none;"
>
    <tbody>
        <tr>
            <td style={{width: "220.7pt", border: "1pt solid windowtext" , padding: "0cm 5.4pt",verticalAlign: "top" }} >
                <p 
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><strong><span 
                //style='font-family:"Arial",sans-serif;'
                >Descripci&oacute;n</span></strong></p>
            </td>
            <td 
           //style{{width: "220.7pt", border-top: "1pt solid windowtext", border-right: "21pt solid windowtext", border-bottom: "1pt solid windowtext", border-image: initial;border-left: none;padding: 0cm 5.4pt;vertical-align: top }}
            >
                <p 
                //style={{margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><strong><span 
                //style='font-family:"Arial",sans-serif;'
                >Tiempo de respuesta</span></strong></p>
            </td>
        </tr>
        <tr>
            <td 
            //style="width: 220.7pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p 
                //</tr>style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span 
                //style='font-family:"Arial",sans-serif;'
                >Creaci&oacute;n de usuario</span></p>
            </td>
            <td 
            //style="width: 220.7pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p 
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span 
                //style='font-family:"Arial",sans-serif;'
                >2 horas</span></p>
            </td>
        </tr>
        <tr>
            <td 
            //style="width: 220.7pt;border-right: 1pt solid windowtext;border-bottom: 1pt solid windowtext;border-left: 1pt solid windowtext;border-image: initial;border-top: none;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p 
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span 
                //style='font-family:"Arial",sans-serif;'
                >Problemas de plataforma durante la creaci&oacute;n, modificaci&oacute;n y administraci&oacute;n de proyectos propios</span></p>
            </td>
            <td 
            //style="width: 220.7pt;border-top: none;border-left: none;border-bottom: 1pt solid windowtext;border-right: 1pt solid windowtext;padding: 0cm 5.4pt;vertical-align: top;"
            >
                <p 
                //style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:.0001pt;line-height:normal;text-align:  justify;'
                ><span 
                //style='font-family:"Arial",sans-serif;'
                >2 horas</span></p>
            </td>
        </tr>
    </tbody>
</table>
{/* {<p style='margin-right:0cm;margin-left:0cm;font-size:15px;font-family:"Calibri",sans-serif;margin-top:0cm;margin-bottom:8.0pt;line-height:107%;text-align:justify;background:white;'></p>}                        */}
<p style={{textAlign: "justify"}}>Para efectos de falla donde la causa sea por problemas t&eacute;cnicos de la plataforma, se tiene asistencia a trav&eacute;s de correo electr&oacute;nico: <a href="mailto:info@gaps-online.com">info@gaps-online.com</a>.</p>
<p style={{textAlign: "justify"}}><strong>GESTI&Oacute;N PARA LA SEGURIDAD Y PRIVACIDAD DE LOS DATOS.</strong></p>
<p style={{textAlign: "justify"}}>La arquitectura de SAX est&aacute; soportada por un servicio de proveedor de Nube denominado Azure, lo que garantiza una disponibilidad del 99.9% de la plataforma y los servicios. Asimismo, la comunicaci&oacute;n al interior del sistema viaja a trav&eacute;s de Redes Privadas Virtuales en la Nube, por lo que los segmentos se encuentran aislados protegiendo los datos m&aacute;s delicados que maneja la aplicaci&oacute;n.</p>
<p style={{textAlign: "justify"}}><strong>8. PROPIEDAD Y RESTRICCIONES</strong></p>
<p style={{textAlign: "justify"}}><strong>8.1</strong>&nbsp;Usted conserva la plena propiedad y los derechos de propiedad intelectual sobre su contenido. XM o sus licenciantes conservan la plena propiedad y los derechos de propiedad intelectual sobre los servicios SAX, incluidos los programas, as&iacute; como los trabajos derivados de los mismos, y sobre todo aquello que fuera desarrollado o entregado por XM o en su nombre en virtud de los planes de servicios.</p>
<p style={{textAlign: "justify"}}><strong>8.2</strong>&nbsp;A efectos de permitir que XM les preste los servicios SAX a Usted y a sus Usuarios, Usted otorga a XM el derecho de usar, procesar y transmitir su contenido durante la vigencia del per&iacute;odo de los servicios y durante cualquier per&iacute;odo adicional posterior a la terminaci&oacute;n, durante el cual XM le brinde acceso para recuperar un archivo de exportaci&oacute;n de su contenido.</p>
<p style={{textAlign: "justify"}}>Teniendo en cuenta que se requieren Programas Complementarios, Usted reconoce que XM podr&aacute; permitir a los terceros proveedores de tales programas que accedan a su contenido, seg&uacute;n se requiera para la interoperaci&oacute;n de dichos programas de terceros con los servicios SAX. XM no ser&aacute; responsable del uso, divulgaci&oacute;n, modificaci&oacute;n o eliminaci&oacute;n de su Contenido como consecuencia de dicho acceso por parte de los terceros proveedores o por la interoperabilidad de dichos programas de terceros con los Servicios SAX.</p>
<p style={{textAlign: "justify"}}><strong>8.3</strong>&nbsp;Los Servicios pueden requerir el uso de tecnolog&iacute;a por parte de terceros proveedores con Licencia por Separado. Usted es responsable de cumplir con los T&eacute;rminos por Separado estipulados por XM que rigen el uso que Usted haga de la Tecnolog&iacute;a de Terceros.</p>
<p style={{textAlign: "justify"}}><strong>8.4</strong>&nbsp;Usted no podr&aacute;, ni podr&aacute; solicitar o permitir a terceros:</p>
<p style={{textAlign: "justify"}}><strong>a)</strong>retirar ni modificar las marcas de ning&uacute;n programa o los avisos referentes a los derechos de propiedad de XM o sus licenciantes;</p>
<p style={{textAlign: "justify"}}><strong>{"b)"}</strong>poner los programas o materiales derivados de los Servicios (a excepci&oacute;n de su Contenido) a disposici&oacute;n de terceros de cualquier modo para su uso en las operaciones de negocios del tercero (salvo que dicho acceso est&eacute; expresamente permitido para los Servicios espec&iacute;ficos que Usted ha adquirido);</p>
<p style={{textAlign: "justify"}}><strong>{"c)"}</strong>modificar, elaborar trabajos derivados, desensamblar, descompilar, efectuar un proceso de ingenier&iacute;a de reversa, reproducir, distribuir, publicar ni descargar cualquier parte de los servicios SAX (las prohibiciones precedentes incluyen, a modo enunciativo y no limitativo, la revisi&oacute;n de las estructuras de datos o materiales similares producidos por los programas), ni acceder ni utilizar los servicios de SAX a fin de elaborar o brindar soporte, y/o prestar asistencia a un tercero para elaborar o brindar soporte, a productos o servicios competitivos con los de XM;</p>
<p style={{textAlign: "justify"}}><strong>8.5</strong>&nbsp;Usted reconoce que el uso de los servicios SAX de manera contraria a los planes de servicios puede afectar en forma adversa el rendimiento de los mismos y/u originar tarifas adicionales. Si los planes permiten que Usted exceda la cantidad pedida (por ejemplo, l&iacute;mites flexibles respecto del n&uacute;mero de Usuarios, sesiones, almacenamiento, etc.), Usted ser&aacute; responsable de adquirir sin demora las cantidades adicionales para cubrir su uso en exceso. Por cada mes en el que Usted no adquiera dicha cantidad adicional, XM podr&aacute; exigirle el pago, adem&aacute;s de las tarifas correspondientes a la cantidad adicional, de una tarifa de uso en exceso aplicable a dichos servicios equivalente al 10% del total de las tarifas correspondientes al plan de acuerdo al valor en que se produjo dicho uso en exceso.</p>
<p style={{textAlign: "justify"}}><strong>8.6</strong>&nbsp;XM podr&aacute; efectuar modificaciones o actualizaciones a los servicios SAX (tales como infraestructura, seguridad, configuraciones t&eacute;cnicas, caracter&iacute;sticas de las aplicaciones, etc.) durante el per&iacute;odo de servicios, incluidas aquellas destinadas a reflejar cambios en la tecnolog&iacute;a, pr&aacute;cticas de la industria, pautas de uso del sistema y disponibilidad de contenido. Las modificaciones se realizar&aacute;n a discreci&oacute;n de XM; no obstante, las modificaciones que XM efect&uacute;e a las Especificaciones del Servicio no tendr&aacute;n como consecuencia una reducci&oacute;n significativa del nivel de rendimiento o disponibilidad de los planes de servicios respectivos que se le presten a Usted.</p>
<p style={{textAlign: "justify"}}><strong>8.7</strong>&nbsp;Usted debe aceptar todos los parches], correcciones de errores, actualizaciones, paquetes de servicio y mantenimiento (en conjunto, los &ldquo;Parches&rdquo;) necesarios para el adecuado funcionamiento y la seguridad de los servicios SAX. XM no es responsable de problemas de rendimiento o seguridad con los Servicios en la Nube que sean consecuencia de su falta de aceptaci&oacute;n de la aplicaci&oacute;n de los Parches necesarios para el adecuado funcionamiento y la seguridad de los Servicios.</p>
<p style={{textAlign: "justify"}}><strong>8.8</strong>&nbsp;En caso de interceptar cualquier conducta o actividad ilegal, XM podr&aacute; denegar o cesar los servicios contratados sin previo aviso. En especial, los servidores no podr&aacute;n ser usados para fines ilegales, por esta raz&oacute;n, se reserva la facultad de desconectar un servidor si &eacute;ste se estuviera utilizando para fines ilegales y dar&aacute; aviso a las autoridades.</p>
<p style={{textAlign: "justify"}}><strong>8.9</strong>&nbsp;XM podr&aacute; suspender en forma temporal su contrase&ntilde;a, cuenta y acceso a los servicios SAX y uso de los mismos si Usted o Sus Usuarios violan cualquier disposici&oacute;n contenida en los presentes t&eacute;rminos y condiciones o si, seg&uacute;n el criterio razonable de XM, los servicios SAX o cualquiera de sus componentes estuvieran por sufrir una amenaza significativa a la seguridad o funcionalidad.</p>
<p style={{textAlign: "justify"}}><strong>RESPONSABILIDADES</strong></p>
<p style={{textAlign: "justify"}}><strong>9.1</strong>&nbsp;XM no ser&aacute; responsable de p&eacute;rdidas, perjuicios y da&ntilde;os como consecuencia del uso, funcionamiento o rendimiento del software y hardware, siendo responsable &uacute;nicamente de los actos realizados que sean necesarios para el cumplimiento de sus obligaciones de acuerdo con estos t&eacute;rminos y condiciones.</p>
<p style={{textAlign: "justify"}}><strong>9.2</strong>&nbsp;Usted ser&aacute; responsable de identificar y autenticar a todos los Usuarios, de aprobar el acceso a los Servicios por parte de dichos Usuarios, de controlar el acceso no autorizado de los Usuarios, y de mantener la confidencialidad de los nombres de usuario, las contrase&ntilde;as y la informaci&oacute;n de cuentas. XM no es responsable de cualquier da&ntilde;o causado por sus Usuarios, incluidas las personas que no estuvieran autorizadas a acceder a los servicios pero que pudieron acceder debido a que los nombres de usuarios, contrase&ntilde;as o cuentas no se cancelaron en forma oportuna. Usted acuerda emplear todos los esfuerzos razonables para evitar que terceros no autorizados accedan a los Servicios.</p>
<p style={{textAlign: "justify"}}><strong>9.3</strong>&nbsp;Si las obligaciones derivadas de los presentes t&eacute;rminos y obligaciones se incumplen debido a eventos irresistibles e imprevisibles, que puedan considerarse caso fortuito o fuerza mayor, ninguna de las Partes estar&aacute; obligada a cumplir los plazos del mismo. La Parte afectada dar&aacute; aviso a la otra a m&aacute;s tardar dentro de las tres (3) d&iacute;as siguientes a la ocurrencia de los hechos, informando sobre el acontecimiento y las causas que lo originaron. Las Partes seguir&aacute;n cumpliendo con sus obligaciones contractuales en lo que sea razonablemente pr&aacute;ctico y buscar&aacute;n de ser posible, medios alternativos para el cumplimiento de los presentes t&eacute;rminos y condiciones. Una vez superados los hechos que constituyeron caso fortuito o fuerza mayor, los servicios continuar&aacute;n ejecut&aacute;ndose en los mismos t&eacute;rminos y condiciones inicialmente pactados. Si no existiere la posibilidad de continuar ejecutando los servicios en virtud de los eventos de fuerza mayor o caso fortuito, las Partes proceder&aacute;n a terminar por mutuo acuerdo.</p>
<p style={{textAlign: "justify"}}>Para los efectos de los presentes t&eacute;rminos y condiciones, se entender&aacute; por caso fortuito o fuerza mayor cualquier hecho imprevisible e irresistible no imputable a la Parte obligada y que no sea consecuencia de culpa suya ni concurra con ella, y que coloque a dicha Parte en la imposibilidad absoluta de cumplir su obligaci&oacute;n.</p>
<p style={{textAlign: "justify"}}><strong>9.4</strong>&nbsp;XM hace sus mejores esfuerzos para implementar medidas de seguridad inform&aacute;tica, sin embargo, no se hace responsable contractual ni extracontractualmente de las consecuencias que el uso de los servicios SAX pueda acarrearle al Usuario, incluyendo, pero sin limitarse a, acciones de virus, gusanos, caballos de Troya, hackers, spyware o da&ntilde;os en sus equipos, alteraciones en el sistema inform&aacute;tico, documentos electr&oacute;nicos o archivos del usuario.</p>
<p style={{textAlign: "justify"}}><br /> <strong>9.5</strong>&nbsp;XM se reserva el derecho de hacer cambios y modificaciones de cualquier tipo a la p&aacute;gina Web y no se responsabiliza por demoras, suspensiones o inconvenientes del sistema de comunicaci&oacute;n o conexi&oacute;n a trav&eacute;s de la p&aacute;gina. Por tanto XM podr&aacute; con o sin previo aviso modificar o discontinuar temporal o permanentemente el servicio y los contenidos de esta p&aacute;gina Web.</p>
<p style={{textAlign: "justify"}}><strong>9.6</strong>&nbsp;XM NO SER&Aacute; RESPONSABLE DE DA&Ntilde;OS INDIRECTOS, INCIDENTALES, ESPECIALES, EJEMPLARES, PUNITIVOS O EMERGENTES, INCLUIDOS EL LUCRO CESANTE, LA P&Eacute;RDIDA DE DATOS, LA LESI&Oacute;N PERSONAL O EL DA&Ntilde;O A LA PROPIEDAD, NI DE PERJUICIOS RELATIVOS, O EN RELACI&Oacute;N CON, O DE OTRO MODO DERIVADOS DE CUALQUIER USO DE LOS SERVICIOS.</p>
<p style={{textAlign: "justify"}}><strong>9.7</strong>&nbsp;XM NO GARANTIZA QUE (A) LOS SERVICIOS SE PRESTAR&Aacute;N SIN ERRORES O EN FORMA ININTERRUMPIDA, NI QUE XM CORREGIR&Aacute; TODOS LOS ERRORES DE LOS SERVICIOS, (B) LOS SERVICIOS FUNCIONAR&Aacute;N EN COMBINACI&Oacute;N CON SU CONTENIDO, O CON CUALQUIER OTRO HARDWARE, SOFTWARE, SISTEMAS, SERVICIOS O DATOS NO PROVISTOS POR XM, Y (C) LOS SERVICIOS CUMPLIR&Aacute;N CON SUS REQUERIMIENTOS, ESPECIFICACIONES O EXPECTATIVAS. USTED RECONOCE QUE XM NO CONTROLA LA TRANSFERENCIA DE DATOS A TRAV&Eacute;S DE SISTEMAS DE COMUNICACIONES, INCLUIDA LA INTERNET, Y QUE LOS SERVICIOS PODR&Aacute;N ESTAR SUJETOS A LIMITACIONES, DEMORAS Y OTROS PROBLEMAS INHERENTES AL USO DE DICHOS SISTEMAS DE COMUNICACIONES. XM NO ES RESPONSABLE DE DEMORAS, INCUMPLIMIENTOS EN EL ENV&Iacute;O, U OTROS DA&Ntilde;OS QUE SEAN CONSECUENCIA DE DICHOS PROBLEMAS. XM NO ES RESPONSABLE DE LOS PROBLEMAS RELACIONADOS CON EL RENDIMIENTO, EL FUNCIONAMIENTO O LA SEGURIDAD DE LOS SERVICIOS QUE SURJAN DE SU CONTENIDO. XM NO FORMULA DECLARACIONES NI PROVEE GARANT&Iacute;AS CON RESPECTO A LA CONFIABILIDAD, EXACTITUD, INTEGRIDAD, CORRECCI&Oacute;N O UTILIDAD DEL CONTENIDO O SERVICIO DE TERCEROS.</p>
<p style={{textAlign: "justify"}}><strong>9.8</strong>&nbsp;SI SE PRODUJERA CUALQUIER INCUMPLIMIENTO DE LA GARANT&Iacute;A DE LOS SERVICIOS, EL &Uacute;NICO RECURSO QUE TENDR&Aacute; USTED Y LA &Uacute;NICA RESPONSABILIDAD QUE TENDR&Aacute; XM CONSISTIR&Aacute; EN LA CORRECCI&Oacute;N DE LOS SERVICIOS DEFICIENTES QUE OCASIONARON EL INCUMPLIMIENTO</p>
<p style={{textAlign: "justify"}}><strong>9.9</strong>&nbsp;EN LA MEDIDA EN QUE LA LEY NO LO PROH&Iacute;BA, ESTAS GARANT&Iacute;AS SON EXCLUSIVAS Y NO EXISTEN OTRAS GARANT&Iacute;AS NI CONDICIONES EXPRESAS NI IMPL&Iacute;CITAS, INCLUIDAS LAS GARANT&Iacute;AS O CONDICIONES RELACIONADAS CON SOFTWARE, HARDWARE, SISTEMAS, REDES O ENTORNOS, O GARANTIAS DE COMERCIABILIDAD O ADECUACI&Oacute;N A UN FIN PARTICULAR.</p>
<p style={{textAlign: "justify"}}><strong>10. SITIOS WEB, CONTENIDOS, PRODUCTOS Y SERVICIOS DE TERCEROS</strong></p>
<p style={{textAlign: "justify"}}>En el evento que los servicios SAX pueden permitirle establecer un enlace, transmitir su Contenido, o de otro modo acceder a otros sitios web, contenidos, productos, servicios e informaci&oacute;n de terceros, XM no se hace responsable de dichos sitios web ni de tales contenidos, productos, servicios e informaci&oacute;n accesibles o proporcionados a trav&eacute;s de los Servicios, y Usted asume todos los riesgos relacionados con el acceso y uso de dichos sitios web y contenidos, productos, servicios e informaci&oacute;n de terceros.</p>
<p style={{textAlign: "justify"}}><strong>11. CONFIDENCIALIDAD</strong></p>
<p style={{textAlign: "justify"}}><strong>11.1</strong>&nbsp;En virtud de los presentes t&eacute;rminos y condiciones, cada una de las partes puede tener acceso a informaci&oacute;n confidencial de la otra parte (&ldquo;Informaci&oacute;n Confidencial&rdquo;). Cada parte acuerda revelar exclusivamente aquella informaci&oacute;n que sea necesaria para el cumplimiento de sus obligaciones. La Informaci&oacute;n Confidencial quedar&aacute; limitada a los t&eacute;rminos y los precios en virtud de los presentes t&eacute;rminos y condiciones, su Contenido, as&iacute; como a toda informaci&oacute;n claramente identificada como confidencial en el momento de su divulgaci&oacute;n.</p>
<p style={{textAlign: "justify"}}><strong>11.2</strong>&nbsp;La Informaci&oacute;n Confidencial de una de las partes no incluir&aacute; informaci&oacute;n que: (a) es o comience a formar parte del dominio p&uacute;blico por causa distinta de la acci&oacute;n u omisi&oacute;n de la otra parte; (b) estuviera en posesi&oacute;n leg&iacute;tima de la otra parte antes de su revelaci&oacute;n y que la otra parte no la hubiera obtenido directa o indirectamente de la parte reveladora; (c) sea leg&iacute;timamente revelada a la otra parte por un tercero sin restricciones respecto de tal revelaci&oacute;n; o (d) sea desarrollada en forma independiente por la otra parte.</p>
<p style={{textAlign: "justify"}}><strong>11.3</strong>&nbsp;Las partes se comprometen a no revelar la Informaci&oacute;n Confidencial de la otra parte a terceros que no sean los mencionados m&aacute;s adelante por un plazo de tres a&ntilde;os a partir de la revelaci&oacute;n de la Informaci&oacute;n Confidencial por la parte reveladora a la parte receptora. No obstante, XM mantendr&aacute; en car&aacute;cter confidencial Su Informaci&oacute;n Confidencial que resida dentro de los servicios en la nube. Las partes podr&aacute;n revelar la Informaci&oacute;n Confidencial &uacute;nicamente a aquellos empleados, representantes o subcontratistas a quienes se les exija protegerla contra la divulgaci&oacute;n no autorizada. XM proteger&aacute; la confidencialidad de su Contenido de conformidad con las pr&aacute;cticas de seguridad que XM determine.</p>
<p style={{textAlign: "justify"}}><strong>12. CUMPLIMIENTO DE NORMAS SOBRE PREVENCI&Oacute;N DEL RIESGO DE LAVADO DE ACTIVOS Y FINANCIACI&Oacute;N DEL TERRORISMO</strong></p>
<p style={{textAlign: "justify"}}>Usted hace constar que:</p>
<p style={{textAlign: "justify"}}><strong>12.1</strong>&nbsp;Cumple con las normas generales y particulares sobre Prevenci&oacute;n al Lavado de Activos y Financiaci&oacute;n al Terrorismo, acepta, entiende y conoce, de manera voluntaria e inequ&iacute;voca, que XM en cumplimiento de su obligaci&oacute;n legal de prevenir y controlar el lavado de activos y la financiaci&oacute;n del terrorismo (LA/FT), podr&aacute; solicitar informaci&oacute;n y/o documentos verificables que soporten la actividad econ&oacute;mica, financiera, comercial como el tipo de operaciones de la Entidad como de las personas naturales o jur&iacute;dicas que la compongan o administren, con el fin de validar el origen de sus recursos que acredite la propiedad al momento o despu&eacute;s de su vinculaci&oacute;n.</p>
<p style={{textAlign: "justify"}}><strong>12.2</strong>&nbsp;En caso tal que Usted no brinde la informaci&oacute;n ver&aacute;s o documentos necesarios para el inicio o continuaci&oacute;n de la relaci&oacute;n contractual, o se llegase a identificar una posible relaci&oacute;n con actividades ilegales, o cuando su nombre y/o cualquiera de sus accionistas, socios o de sus representantes legales llegare a ser: (i) vinculado por parte de las autoridades nacionales e internacionales a cualquier tipo de investigaci&oacute;n por delitos estipulados en los art&iacute;culos 319 y 320 del Cap&iacute;tulo Cuarto; art&iacute;culos 323, 326 y 327 del Cap&iacute;tulo Quinto del T&iacute;tulo X Delitos Contra el Orden Econ&oacute;mico Social y T&iacute;tulo XII Cap&iacute;tulo Primero de la Ley 599 de 2000; (ii) incluido en listas lista del Consejo de Seguridad de la Organizaci&oacute;n de las Naciones Unidas ONU y la lista Office of Foreign Assets Control (OFAC) de la Oficina del Tesoro de Estados Unidos, como aquellas listas p&uacute;blicas para el control de lavado de activos y financiaci&oacute;n del terrorismo administradas por cualquier autoridad nacional o extranjera; (iii) condenado por parte de las autoridades nacionales o internacionales en cualquier tipo de proceso judicial relacionado con la comisi&oacute;n de los anteriores delitos; o iv) llegare a ser se&ntilde;alado directa y p&uacute;blicamente por cualquier medio de amplia difusi&oacute;n nacional como investigados por delitos mencionados en el literal (i); por considerarlo una causal objetiva, XM de acuerdo a sus pol&iacute;ticas internas podr&aacute; cancelar o terminar unilateralmente el presente acuerdo en cualquier momento y sin previo aviso.</p>
<p style={{textAlign: "justify"}}><strong>12.3</strong> Usted indemnizar&aacute; y mantendr&aacute; indemne y libre de da&ntilde;o a XM por cualquier multa, da&ntilde;o o perjuicio que sufra por o con ocasi&oacute;n del incumplimiento por parte de Usted de las medidas o normas de Prevenci&oacute;n al Lavado de Activos y Financiaci&oacute;n al Terrorismo.</p>
<p style={{textAlign: "justify"}}><strong>13 DERECHOS DE AUTOR Y PROTECCI&Oacute;N MARCARIA</strong></p>
<p style={{textAlign: "justify"}}><strong>13.1</strong>&nbsp;XM pone a disposici&oacute;n del Usuario una serie de informaci&oacute;n a trav&eacute;s de los servicios SAX. Dichos materiales y documentos est&aacute;n normados por la presente pol&iacute;tica y son en parte la reproducci&oacute;n de informaci&oacute;n suministrada por usted y en parte informaci&oacute;n procesada, elaborada y desarrollada por XM. Sobre esta &uacute;ltima parte; existen derechos de autor a favor de XM.</p>
<p style={{textAlign: "justify"}}><strong>13.2</strong>&nbsp;Toda la informaci&oacute;n, textos, gr&aacute;ficas, presentaci&oacute;n y dise&ntilde;o, as&iacute; como el software, los c&oacute;digos fuente y en general el programa de ordenador que constituye y soporta los servicios SAX tienen protecci&oacute;n de Derechos de Autor de acuerdo con la legislaci&oacute;n nacional y con normas internacionales.</p>
<p style={{textAlign: "justify"}}><strong>13.3</strong>&nbsp;Todos los signos distintivos (marcas, logotipos, botones, combinaci&oacute;n de colores, presentaci&oacute;n de contenidos y su estructura, botones o banners) gozan de protecci&oacute;n marcaria y son propiedad exclusiva de XM. Por lo tanto, ninguno de los elementos anteriormente mencionados podr&aacute; ser reproducido, comunicado, distribuido, copiado, utilizado, transmitido, vendido o comercializado de ning&uacute;n modo sin la previa autorizaci&oacute;n escrita de XM.</p>
<p style={{textAlign: "justify"}}><strong>13.4</strong>&nbsp;En ning&uacute;n caso debe entenderse que XM otorga o garantiza licencias de uso de sus signos distintivos ni de derechos de autor.</p>
<p style={{textAlign: "justify"}}><strong>14. CL&Aacute;USULA GENERALES</strong></p>
<p style={{textAlign: "justify"}}><strong>14.1</strong>&nbsp;Las partes se someten a la jurisdicci&oacute;n de los tribunales colombianos y se aplicar&aacute; la ley del Estado colombiano para la resoluci&oacute;n de cualquier controversia que pudiera surgir, renunciando a su fuero si &eacute;ste fuese otro.</p>
<p style={{textAlign: "justify"}}><strong>14.2</strong>&nbsp;Toda notificaci&oacute;n requerida ser&aacute; cursada a Usted por escrito a la direcci&oacute;n de correo electr&oacute;nico proporcionada al momento del registro..</p>
<p style={{textAlign: "justify"}}><strong>14.3</strong>&nbsp;Usted no podr&aacute; ceder los derechos ni otorgar ni transferir los Servicios SAX (incluidos los Programas de XM) ni derecho alguno sobre los mismos, a otra persona f&iacute;sica o jur&iacute;dica.</p>
<p style={{textAlign: "justify"}}><strong>14.4</strong>&nbsp;Las partes aceptan que no existe asociaci&oacute;n, empresa conjunta, joint venture, ni relaci&oacute;n de agencia o representaci&oacute;n alguna entre ellas. Usted y XM ser&aacute;n responsables de los pagos a sus respectivos empleados, incluidos los seguros e impuestos sobre la n&oacute;mina que correspondan. Usted defender&aacute; e indemnizar&aacute; a XM frente a toda responsabilidad emergente de las leyes, ordenanzas o reglamentaciones aplicables con relaci&oacute;n a la terminaci&oacute;n o variaci&oacute;n de las condiciones de empleo que Usted disponga respecto de cualquiera de Sus empleados en relaci&oacute;n con los Servicios.</p>
<p style={{textAlign: "justify"}}><strong>14.5</strong>&nbsp;En caso de que cualquier cl&aacute;usula de estos t&eacute;rminos y condiciones fuese considerada nula o inexigible, las restantes disposiciones del mismo permanecer&aacute;n en vigencia y dicha cl&aacute;usula ser&aacute; reemplazada por otra que resulte acorde al prop&oacute;sito y la finalidad perseguida por las Partes.</p>
<p style={{textAlign: "justify"}}><strong>14.6</strong>&nbsp;Usted deber&aacute; obtener, a su propio costo y cargo, los derechos y consentimientos de terceros que sean necesarios para su Contenido.</p>
<p style={{textAlign: "justify"}}><strong>14.7</strong>&nbsp;Usted acuerda brindar a XM toda la informaci&oacute;n, acceso y plena cooperaci&oacute;n que resulten razonablemente necesarios para que XM pueda prestar los servicios SAX y Usted deber&aacute; llevar a cabo los actos que, seg&uacute;n se indique en la orden, se encuentran bajo su responsabilidad.</p>
<p style={{textAlign: "justify"}}><strong>14.8</strong>&nbsp;Usted continuar&aacute; siendo exclusivamente responsable del cumplimiento de las reglamentaciones aplicables en relaci&oacute;n con el uso que Usted haga de los servicios SAX.</p>
<p style={{textAlign: "justify"}}><strong>14.9</strong>&nbsp;XM podr&aacute; auditar el uso que Usted haga de los servicios SAX (por ejemplo, mediante herramientas de software) a fin de evaluar si el uso que Usted hace de los Servicios es acorde a lo establecido en su orden y en los presentes t&eacute;rminos y condiciones.</p>
<p style={{textAlign: "justify"}}><strong>14.10</strong>&nbsp;Usted acepta que estos t&eacute;rminos y condiciones, y la orden correspondiente, constituyen la totalidad del acuerdo respecto de los servicios que Usted solicite y reemplazan a todos los contratos o declaraciones anteriores o contempor&aacute;neos, sean escritos o verbales, relacionados con dichos Servicios.</p>

    </div>
  </div>

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}