import Header from "../layouts/header";
import Title from "../components/title";
import Footer from "../components/footer";
import TutorialsList from "../components/tutorials/tutorials-list";



export default function Tutorials(props) {

    // let { state } = useLocation();
    // const plan = state.plan;

    return (

        <>
            <Header />
            <Title title='Aprende a usar SAX' subtitle='SAX te permite identificar, en tan solo segundos, todos los escenarios y sus contingencias para analizar la seguridad de tu red' />
            <TutorialsList />
            <Footer />

        </>

    )
}