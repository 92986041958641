import { useEffect, useState } from "react";
import Header from "../layouts/header";
import DescirptionHome from "../components/landing/description-home";
import TryApp from "../components/landing/try-app";
import Plans from "../components/landing/plans";
import FeaturesSax from "../components/landing/feature-sax";
import Footer from "../components/footer";
import { homePlans } from "../actions/plans"
import  ModalDialogComponent from '../components/dialog/dialog-component'
import  ModalDialogComponentNews from '../components/dialog/dialog-component-news'

function transformToAssocArray( prmstr ) {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
    }
    return params;
}
function Landing(props) {
    const [plans, setPlans] = useState([]);
    const [modalMessages, setModalMessages] = useState(false);
    const [modalMessagesNotification, setModalMessagesNotification] = useState(false);
    const [modalMessageActiveAccount, setModalMessageActiveAccount] = useState(false);

    const [message, setMessage] = useState(false);

    useEffect(() => {
        !localStorage.getItem('saxToken') && setModalMessagesNotification(false) 
        let  params = window.location.search.substr(1);
        params =  params ? transformToAssocArray(params) : {};
        if(params['expirate']) {
            setModalMessages(true);
        };
        
        if ( params['active_user'] ) {
            setModalMessageActiveAccount(true);
        };
         
        homePlans()
            .then(response => {

                setPlans(response.response)

            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    return (
        <>
        <ModalDialogComponent
        view = {modalMessages}
        tittle ={'Sesión expiro'}
        message={'La sesión ha expirado porfavor vuelva a iniciar sesión en la plataforma.'}
        buttons={[
            {value:'Aceptar',onclick:()=>{setModalMessages(false); }}
        ]}
        />

        <ModalDialogComponentNews
        view = {modalMessagesNotification}
        tittle ={'Nuevas configuraciones'}
        messageAlert={'¡Actualización de Infraestructura Exitosa!'}
        message={`¡Gracias por su comprensión y apoyo durante nuestra migración de infraestructura! Si experimenta problemas, por favor, notifíquenos a info@sax.com.co.
        Estamos comprometidos a solucionar cualquier novedad que pueda surgir y brindar la mejor experiencia posible con SAX.`}
        buttons={[
            {value:'Aceptar',onclick:()=>{setModalMessagesNotification(false)}}
        ]}
        />

        <ModalDialogComponentNews
        view= { modalMessageActiveAccount }
        tittle={ 'Sesión Activa en Otro Dispositivo' }
        messageAlert ={ 'Parece que ya tienes una sesión activa en otro dispositivo.' }
        message= { `Por favor, cierra la sesión en ese dispositivo y vuelve a intentarlo. Si el problema persiste, comunícate con tu contacto de soporte o escríbenos a info@sax.com.co.` }
        buttons= { [
            { value: 'Entendido', onclick: ()=>{ setModalMessageActiveAccount( false ) } }
        ]}
        />

            <Header {...props} />
            <DescirptionHome />
            <TryApp />
            <FeaturesSax />
            <Plans plans={plans} />
            <Footer />
        </>
    )
}

export default Landing;